import axios from 'axios'
import VueJwtDecode from 'vue-jwt-decode'
import AddMinutes from 'date-fns/addMinutes'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import router from "@/router";
import { v4 as generateGuid } from 'uuid';

//axios add token auth in config
axios.interceptors.request.use(
    config => {
      if (!config.headers.Authorization) {
        const token = localStorage.getItem('accessToken');
  
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
  
      return config;
    },
    error => Promise.reject(error)
  );

  //axios 401 redirect to login form
  axios.interceptors.response.use(response => {
    return response;
 }, error => {
   if (error.response.status === 401) {
        if(!window.location.href.includes("login-form"))
            router.push("/login-form");
   }
   return error;
 });

export default {
    namespaced: true,
    state: {
      token: localStorage.getItem('accessToken') || null,
      refreshToken: localStorage.getItem('refreshToken') || null,
      expiredDate: localStorage.getItem('expiredDate') || null,
      sessionOid: localStorage.getItem('sessionOid') || null,
      userName: localStorage.getItem('userName') || null,
      userId: localStorage.getItem('userId') || null,
      role: localStorage.getItem('role') || null
    },
    getters: {
      loggedIn(state) {
            return state.token !== null
      },
      getToken(state) {
        return state.token
      },
      getExpiredDate(state) {
        return state.expiredDate
      },
      getFormatedExpiredDate(state) {
        if(state.expiredDate != null)
        {
          var milliseconds = Date.parse(state.expiredDate);
          if (milliseconds.isNaN || milliseconds != undefined)
          {
            const expiredDate = new Date(0);
            expiredDate.setUTCMilliseconds(Date.parse(state.expiredDate));
            return expiredDate
          }
        }
        return null;
      },
      getUser(state) {
        return state.userId;
      }, 
      getUserRole(state) {
        return state.role;
      }, 
      getUserName(state) {
        return state.userName;
      }
    },
    mutations: {
      setJwtData(state, {accessToken, refreshToken, expiredDate, sessionOid, userName, userId, role}) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('expiredDate', expiredDate);
        localStorage.setItem('sessionOid', sessionOid);
        localStorage.setItem('userName', userName);
        localStorage.setItem('userId', userId);
        localStorage.setItem('role', role);
        
        state.token = accessToken;
        state.refreshToken = refreshToken;
        state.expiredDate = expiredDate;
        state.sessionOid = sessionOid;
        state.role = role;
        state.userName = userName;
        state.userId = userId;
        state.role = role;
      },
      destroyJwtData(state) {
        state.token = null;
        state.refreshToken = null;
        state.expiredDate = null;
        state.sessionOid = null;
        state.role = null;
        state.userName = null;
        state.userId = null;
        state.role = null
      },
      destroyJwtLocalStorage() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expiredDate');
        localStorage.removeItem('sessionOid');
        localStorage.removeItem('role');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        localStorage.removeItem('role');
      }
    },
    actions: {
      destroyToken(context) {
        if (context.getters.loggedIn) {
          return new Promise((resolve, reject) => {
            axios.post('/api/Account/Logout', {"sessionOid" : this.state.sessionOid})
            .then(response => {
              console.log(response);
              context.commit('destroyJwtLocalStorage');
              context.commit('destroyJwtData');
              resolve(response);
              // console.log(response);
              // context.commit('addTodo', response.data)
            })
            .catch(error => {
              context.commit('destroyJwtLocalStorage');
              context.commit('destroyJwtData');
              reject(error);
            })
          })
        }
      },
      Authorization(context, credentials) {
        return new Promise((resolve, reject) => {
          axios.post('api/Security/Authenticate', {"Username": credentials.phone, "Password": credentials.password, "SessionID": generateGuid()})
          .then(response => {
            //console.log(response);
            if(response.response != undefined)
                  if(response.response.status != 200)
                    reject(response.response.data);

            resolve(response);
            const accessToken = response.data.accessToken;
            const refreshToken = response.data.refreshToken;
            const sessionOid = response.data.sessionOid;
            const userName = response.data.userName;
            const userId = response.data.userId;
            const role = response.data.role;
            
            var jwtdata = VueJwtDecode.decode(accessToken);
            const expiredDate = new Date(0);
            expiredDate.setUTCSeconds(jwtdata.exp);
            
            context.commit('setJwtData', {accessToken, refreshToken, expiredDate, sessionOid, userName, userId, role});
            resolve(response);
            context.dispatch('doctor/getDoctor', null, {root: true});
                // context.commit('addTodo', response.data)
              })
              .catch(error => {
                //console.log(error);
                reject(error);
              })
            })
        },
        SmsSend(context, credentials) {
          return new Promise((resolve, reject) => {
             axios.post('api/Security/SendSms', {"Username": credentials.phone, "SessionID": generateGuid(), "isRegistering": credentials.isRegistering})
              .then(response => {
                console.log(response);

                if(response.response != undefined)
                  if(response.response.status != 200)
                    reject(response.response.data);
                
                resolve(response);
                
                // context.commit('addTodo', response.data)
              })
              .catch(error => {
                reject(error);
              })
            })
        },
        SmsConfirm(context, credentials) {
          return new Promise((resolve, reject) => {
             axios.post('api/Security/ConfirmSms', {"Username": credentials.phone, "SessionID": generateGuid(), "SmsCode": credentials.smsCode.toString()})
              .then(response => {
                console.log(response);
                
                if(response.response != undefined)
                  if(response.response.status != 200)
                    reject(response.response.data);
                
                const userName = credentials.phone;
                context.commit('setJwtData', {userName});
                resolve(response);
                // context.commit('addTodo', response.data)
              })
              .catch(error => {
                console.log(error);
                reject(error);
              })
            })
        },
        Register(context, credentials) {
          return new Promise((resolve, reject) => {
             axios.post('api/Security/RegisterDoctor', {"Username": credentials.phone, "Password": credentials.password, "SessionID": generateGuid(), "FirstName": credentials.firstName, "LastName": credentials.lastName})
              .then(response => {
                console.log(response);

                if(response.response != undefined)
                  if(response.response.status != 200)
                    reject(response.response.data);

                
                resolve(response);
                
                // context.commit('addTodo', response.data)
              })
              .catch(error => {
                console.log(error);
                reject(error);
              })
            })
        },
        ResetPassword(context, credentials) {
          return new Promise((resolve, reject) => {
             axios.post('api/Security/ForgotPassword', {"Username": credentials.phone, "NewPassword": credentials.newPassword, "SessionID": generateGuid()})
              .then(response => {
                console.log(response);

                if(response.response != undefined)
                  if(response.response.status != 200)
                    reject(response.response.data);

                resolve(response);
                
                // context.commit('addTodo', response.data)
              })
              .catch(error => {
                console.log(error);
                reject(error);
              })
            })
        },
        initRefreshTokenSession({commit, dispatch, getters}) {
            console.log("refresh token");
            try {
                var expiredDate = getters.getFormatedExpiredDate;
                console.log(expiredDate);
                
                if(expiredDate != null)
                {
                    const expiredDateMinusMinute = AddMinutes(expiredDate, -1);
                    if (isAfter(new Date(), expiredDateMinusMinute)) //check accessToken expirated
                    {
                        if(isBefore(new Date(), AddMinutes(expiredDate, 60))) // check refreshToken expired
                        {
                            console.log("isBefore");
                            dispatch("refreshToken").catch(() => {
                                if(!window.location.href.includes("login-form"))
                                    router.push("/login-form");
                            });
                        }
                        else
                        {
                            commit('destroyJwtLocalStorage');
                            commit('destroyJwtData');
                            if(!window.location.href.includes("login-form"))
                                router.push("/login-form");
                        }
                    }
                }
                else
                {
                    commit('destroyJwtData');
                    if(!window.location.href.includes("login-form"))
                        router.push("/login-form");
                }
            } catch (error) {
                console.log(error);
                if(!window.location.href.includes("login-form"))
                    router.push("/login-form");
            }
    
            /**/
            setTimeout(()=>{console.log("set timeout"); dispatch("initRefreshTokenSession")}, 60000); 
            
        },
        refreshToken(context) {
            return new Promise((resolve, reject) => {
                axios.post('/api/Account/RefreshToken', {"refreshToken" : localStorage.getItem('refreshToken')})
                .then(response => {
                        console.log(response);
                        const accessToken = response.data.accessToken;
                        const refreshToken = response.data.refreshToken;
                        const sessionOid = response.data.sessionOid;
                        
                        var jwtdata = VueJwtDecode.decode(accessToken);
                        const expiredDate = new Date(0);
                        expiredDate.setUTCSeconds(jwtdata.exp);
                        console.log(jwtdata);
                        console.log(expiredDate)
    
                        localStorage.setItem('accessToken', accessToken);
                        localStorage.setItem('refreshToken', refreshToken);
                        localStorage.setItem('expiredDate', expiredDate);
                        localStorage.setItem('sessionOid', sessionOid);
    
                        context.commit('setJwtData', {accessToken, refreshToken, expiredDate, sessionOid});
                        resolve(response);
                    // console.log(response);
                    // context.commit('addTodo', response.data)
                })
                .catch(error => {             
                    reject(error);
                })
            })
        }
      }
}