import axios from 'axios';

export default {
  namespaced: true,
  state: {
    users: [],
    notifications: [],
  },
  getters: {
    getUsers(state) {
      return state.users;
    },
    getNotifications(state) {
      return state.notifications;
    },
  },
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
  },
  actions: {
    getUsers(context) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/Admin/GetAllUsers')
          .then((response) => {
            let tempDoc = [];
            tempDoc.push(response.data);
            context.commit('setUsers', tempDoc);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    deleteUser(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/Admin/DeleteUser', data)
          .then((response) => {
            context.dispatch('getUsers');
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    updateUser(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/Admin/UpdateUser', data)
          .then((response) => {
            context.dispatch('getUsers');
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    createUser(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/Admin/CreateUser', data)
          .then((response) => {
            context.dispatch('getUsers');
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    getNotifications(context) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/Admin/GetNotifications')
          .then((response) => {
            let tempDoc = [];
            tempDoc.push(response.data);
            context.commit('setNotifications', tempDoc);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    createNotifications(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/Admin/CreateNotifications', data)
          .then((response) => {
            context.dispatch('getNotifications');
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    updateNotifications(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/Admin/UpdateNotifications', data)
          .then((response) => {
            context.dispatch('getNotifications');
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    deleteNotifications(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/Admin/DeleteNotifications', data)
          .then((response) => {
            context.dispatch('getNotifications');
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
  },
};
