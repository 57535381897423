<template>
  <div>

    <div class="date-block">
      <div class="date">
        <p>{{$t('Patients.Indicators.PeriodStart')}}</p>
        <DxDateBox
          id="selected-date"
          width="100%"
          v-model="dateFrom"
          @value-changed="onDateChanged"
        />
      </div>
      <div>
        <p>{{$t('Patients.Indicators.PeriodEnd')}}</p>
        <DxDateBox
          id="selected-date"
          width="100%"
          v-model="dateTo"
          @value-changed="onDateChanged"
        />
      </div>
    </div>
    <!--<DxDateBox v-model="dateSelected"/>-->

    <DxDataGrid
      class="dx-card wide-card container"
      :data-source="medHistoryData"
      height="920px"
      :selection="{ mode: 'single' }"
      :show-borders="false"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :ref="dataGrid"
      :noDataText="$t('NoData')"
      @content-ready="onContentReady"
      @cell-prepared="onCellPrepared"
    >

      <DxPaging :page-size="12" />
      <DxPager :show-page-size-selector="true" :show-info="true" />
      <DxSearchPanel
        :visible="true"
        :width="400"
        :placeholder="$t('Search')"
      />

      <DxColumn
        data-field="medicamentId"
        :caption="$t('Patients.TreatmentSchemas.MedicamentName')"
        sort-order="asc"
        cell-template="medicamentName-cell-template"
      />

      <DxColumn
        :width="135"
        data-field="dose"
        :caption="$t('Patients.TreatmentSchemas.Dose')"
      />

      <DxColumn
        :width="150"
        data-field="timesADay"
        :caption="$t('Patients.TreatmentSchemas.TimesADay')"
      />

      <DxColumn
        :width="150"
        data-field="takingCounter"
        :caption="$t('Patients.MedHistory.Taken')"
      />

      <DxColumn
        data-field="takingTime"
        :group-index="0"
        :caption="$t('Date')"
      />
      <DxSummary v-if="contentReady" :recalculateWhileEditing="true">
        <DxTotalItem column="timesADay" summary-type="sum" :display-format="$t('Sum') + ': {0}'"/>
        <DxTotalItem column="takingCounter" summary-type="sum" :display-format="$t('Sum') + ': {0}'"/>
        <DxGroupItem column="takingCounter" summary-type="sum" :display-format="$t('Patients.MedHistory.Taken') + ': {0}'" :show-in-group-footer="false" :align-by-column="true"/>
      </DxSummary>
      <template #medicamentName-cell-template="{data}">
        {{getMedicamentName(data)}}
      </template>
    </DxDataGrid>

  </div>
</template>

<script>
import DxDataGrid, {
    DxColumn,
    DxSearchPanel,
    DxPager,
    DxPaging,
    DxSummary,
    DxTotalItem,
    DxGroupItem
} from "devextreme-vue/data-grid";
import DxDateBox from 'devextreme-vue/date-box';
export default {
    data() {
        return {
            dataGrid: "gridInstance",
            dateSelected: new Date(),
            dateTo: new Date(),
            dateFrom: new Date(),
            contentReady: false,
        }
    },
    created() {
        this.$store.dispatch('indicator/getMedsIndicatorsByPatientId', {PatientId: parseInt(this.$route.params.id), DateFrom: this.dateFrom.toJSON(), DateTo: this.dateTo.toJSON()});
        this.$store.dispatch('treatmentschema/getTreatmentSchemas', this.$route.params.id);
    },
    computed: {
        medHistoryData() {
            if (this.$store.getters['indicator/getMedHistory'][0] != null) {
              this.$store.getters['indicator/getMedHistory'][0].forEach(element => {
                element.takingTime = new Date(element.takingTime);
              });
            }
            return this.$store.getters['indicator/getMedHistory'][0];
        },
        medicaments() {
            return this.$store.getters['medicament/getMedicaments'];
        },
        treatmentSchemas() {
            return this.$store.getters['treatmentschema/getTreatmentSchemas'];
        },
        DateTo() {
          this.dateTo.setHours(0,0,0,0);
          return this.dateTo;
        },
        DateFrom() {
          this.dateFrom.setHours(0,0,0,0);
          return this.dateFrom;
        },
        /*MaxDate() {
          var date = new Date(this.dateFrom);
          date.setDate(this.dateFrom.getDate() + 30);
          return date;
        }*/
    },
    methods: {
      async onDateChanged() {
        await this.$store.dispatch('indicator/getMedsIndicatorsByPatientId', {PatientId: parseInt(this.$route.params.id), DateFrom: this.dateFrom.toJSON(), DateTo: this.dateTo.toJSON()});
      },
      onContentReady() {
        this.contentReady = true;
      },
      onCellPrepared(e) {
        if (e.data.takingCounter == 0 && e.columnIndex == 4)
          e.cellElement.style.backgroundColor = "#ff7575";
        else if (e.data.timesADay > e.data.takingCounter && e.columnIndex == 4)
          e.cellElement.style.backgroundColor = "#ffe675";
      },
      getMedicamentName(data) {
        return this.treatmentSchemas.filter(x => x.medicamentId == data.value)[0].medicamentName;
      }
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxSearchPanel,
        DxPager,
        DxPaging,
        DxDateBox,
        DxSummary,
        DxTotalItem,
        DxGroupItem
    }
}
</script>
<style scoped>
.date-block {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px auto auto 50px;
  font-size: 18px;
}
.date {
  margin-right: 250px;
}
.container {
  padding: 3% 10%;
}
</style>