export const roles = [{
    ID: 0,
    Role: 'Администратор'
},
{
    ID: 1,
    Role: 'Пациент'
},
{
    ID: 2,
    Role: 'Врач'
}];

export default {
    getRoles() {
        return roles;
    }
}