import axios from 'axios'

export default {
  namespaced: true,
  state: {
    sugar: [],
    sugarCards: [],
    medHistory: [],
    pressure: [],
    pressureCards: [],
    hemoglobin: [],
    hemoglobinCards: []
  },
  getters: {
    getSugar(state) {
      return state.sugar;
    },
    getSugarCards(state) {
      return state.sugarCards;
    },
    getMedHistory(state) {
      return state.medHistory;
    },
    getPressure(state) {
      return state.pressure;
    },
    getPressureCards(state) {
      return state.pressureCards;
    },
    getHemoglobin(state) {
      return state.hemoglobin;
    },
    getHemoglobinCards(state) {
      return state.hemoglobinCards;
    }
  },
  mutations: {
    setSugar(state, sugar) {
      state.sugar = sugar;
    },
    setSugarCards(state, sugarCards) {
      state.sugarCards = sugarCards;
    },
    setMedHistory(state, medHistory) {
      state.medHistory = medHistory;
    },
    setPressure(state, pressure) {
      state.pressure = pressure;
    },
    setPressureCards(state, pressureCards) {
      state.pressureCards = pressureCards;
    },
    setHemoglobin(state, hemoglobin) {
      state.hemoglobin = hemoglobin;
    },
    setHemoglobinCards(state, hemoglobinCards) {
      state.hemoglobinCards = hemoglobinCards;
    }
  },
  actions: {
    async getMaxAvgSugar(context, sugarModel) {
      return await new Promise((resolve, reject) => {
        axios.post('/api/Indicator/GetMaxAvgSugar/', sugarModel)
        .then(response => {
          if (response.status === 200) {
            let tempDoc = [];
            response.data.forEach(doc => {
              const data = {
                Date: doc.date,
                MaxValue: doc.maxValue,
                AvgValue: doc.averageValue
              }
              tempDoc.push(data);
            });
            context.commit("setSugar", tempDoc);
          } else if (response.response.status === 400) {
            context.commit("setSugar", null);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
      })
    },
    async getSugarByDay(context, sugarModel) {
      return await new Promise((resolve, reject) => {
        axios.post('/api/Indicator/GetSugarByDay/', sugarModel)
        .then(response => {
          if (response.status === 200) {
            let tempDoc = [];
            response.data.forEach(doc => {
              const data = {
                Date: new Date(doc.date).toLocaleDateString('ru-RU', {
                  weekday: 'short', // long, short, narrow
                  day: 'numeric', // numeric, 2-digit
                  year: 'numeric', // numeric, 2-digit
                  month: 'numeric', // numeric, 2-digit, long, short, narrow
                }),
                Time: doc.time,
                Value: doc.value,
              }
              tempDoc.push(data);
            });
            context.commit("setSugarCards", tempDoc);
          } else if (response.response.status === 400) {
            context.commit("setSugarCards", null);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
      })
    },
    getMedsIndicatorsByPatientId(context, data) {
      return new Promise((resolve, reject) => {
        axios.post('/api/Indicator/GetMedHistoryByPatientId/', data)
        .then(response => {
          let tempDoc = [];
          tempDoc.push(response.data);
          context.commit("setMedHistory", tempDoc);
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
      })
    },
    async getMaxPressure(context, pressureModel) {
      return await new Promise((resolve, reject) => {
        axios.post('/api/Indicator/GetMaxPressure/', pressureModel)
        .then(response => {
          if (response.status === 200) {
            let tempDoc = [];
            response.data.forEach(doc => {
              const data = {
                Date: doc.date,
                LowerMaxValue: doc.lowerValue,
                HigherMaxValue: doc.higherMaxValue,
              }
              tempDoc.push(data);
            });
            context.commit("setPressure", tempDoc);
          } else if (response.response.status === 400) {
            context.commit("setPressure", null);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
      })
    },
    async getPressureByDay(context, pressureModel) {
      return await new Promise((resolve, reject) => {
        axios.post('/api/Indicator/GetPressureByDay/', pressureModel)
        .then(response => {
          if (response.status === 200) {
            let tempDoc = [];
            response.data.forEach(doc => {
              const data = {
                Date: new Date(doc.date).toLocaleDateString('ru-RU', {
                  weekday: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  month: 'numeric',
                }),
                Time: doc.time,
                LowerPressure: doc.lowerValue,
                HigherPressure: doc.highValue,
              }
              tempDoc.push(data);
            });
            context.commit("setPressureCards", tempDoc);
          } else if (response.response.status === 400) {
            context.commit("setPressureCards", null);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
      })
    },
    async getAvgHemoglobin(context, hemoglobinModel) {
      return await new Promise((resolve, reject) => {
        axios.post('/api/Indicator/GetAvgHemoglobin/', hemoglobinModel)
        .then(response => {
          if (response.status === 200) {
            let tempDoc = [];
            response.data.forEach(doc => {
              const data = {
                Date: doc.date,
                AvgValue: doc.averageValue
              }
              tempDoc.push(data);
            });
            context.commit("setHemoglobin", tempDoc);
          } else if (response.response.status === 400) {
            context.commit("setHemoglobin", null);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
      })
    },
    async getHemoglobinByMonth(context, hemoglobinModel) {
      return await new Promise((resolve, reject) => {
        axios.post('/api/Indicator/GetHemoglobinByMonth/', hemoglobinModel)
        .then(response => {
          if (response.status === 200) {
            let tempDoc = [];
            response.data.forEach(doc => {
              const data = {
                Date: new Date(doc.date).toLocaleDateString('ru-RU', {
                  weekday: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  month: 'numeric',
                }),
                Month: new Date(doc.date).toLocaleDateString('ru-RU', { month: 'long'}).charAt(0).toUpperCase() + new Date(doc.date).toLocaleDateString('ru-RU', { month: 'long'}).slice(1),
                Value: doc.value,
              }
              tempDoc.push(data);
            });
            context.commit("setHemoglobinCards", tempDoc);
          } else if (response.response.status === 400) {
            context.commit("setHemoglobinCards", null);
          }
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
      })
    },
  }
}