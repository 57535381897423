export const cities = [{
    ID: 1,
    City: 'Алма-Ата',
    Region: 'Алматинская область'
},
{
    ID: 2,
    City: 'Талдыкорган',
    Region: 'Алматинская область'
},
{
    ID: 3,
    City: 'Нур-Султан',
    Region: 'Акмолинская область'
},
{
    ID: 4,
    City: 'Кокшетау',
    Region: 'Акмолинская область'
},
{
    ID: 5,
    City: 'Шымкент',
    Region: 'Южно-Казахстанская область'
},
{
    ID: 6,
    City: 'Актобе',
    Region: 'Актюбинская область'
},
{
    ID: 7,
    City: 'Караганда',
    Region: 'Карагандинская область'
},
{
    ID: 8,
    City: 'Темиртау',
    Region: 'Карагандинская область'
},
{
    ID: 9,
    City: 'Карагандинская',
    Region: 'Карагандинская область'
},
{
    ID: 10,
    City: 'Тараз',
    Region: 'Жамбылская область'
},
{
    ID: 11,
    City: 'Павлодар',
    Region: 'Павлодарская область'
},
{
    ID: 12,
    City: 'Экибастуз',
    Region: 'Павлодарская область'
},
{
    ID: 13,
    City: 'Усть-Каменогорск',
    Region: 'Восточно-Казахстанская область'
},
{
    ID: 14,
    City: 'Семей',
    Region: 'Восточно-Казахстанская область'
},
{
    ID: 15,
    City: 'Атырау',
    Region: 'Атырауская область'
},
{
    ID: 16,
    City: 'Костанай',
    Region: 'Костанайская область'
},
{
    ID: 17,
    City: 'Рудный',
    Region: 'Костанайская область'
},
{
    ID: 18,
    City: 'Кызылорда',
    Region: 'Кызылординская область'
},
{
    ID: 19,
    City: 'Уральск',
    Region: 'Западно-Казахстанская область'
},
{
    ID: 20,
    City: 'Петропавловск',
    Region: 'Северо-Казахстанская область'
},
{
    ID: 21,
    City: 'Актау',
    Region: 'Мангистауская область'
},
{
    ID: 22,
    City: 'Туркестан',
    Region: 'Туркестанская область'
}];

export default {
    getCities() {
        return cities;
    }
}