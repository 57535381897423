import axios from 'axios'

export default {
    namespaced: true,
    state: {
        doctor: [],
        language: localStorage.getItem('language') || null
    },
    getters: {
        getDoctor(state) {
            return state.doctor;
        },
        getLanguage(state) {
            return state.language;
        }
    },
    mutations: {
        setDoctor(state, doctor) {
            state.doctor = doctor;
            localStorage.setItem('language', doctor[0].Language);
            state.language = doctor[0].Language;
        }
    },
    actions: {
        getDoctor(context) {
            return new Promise((resolve, reject) => {
                axios.get('api/Doctor/GetUserById?id=' + context.rootState.auth.userId)
                .then(response => {
                    let tempDoc = [];
                    tempDoc.push(response.data);
                    context.commit("setDoctor", tempDoc);
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                })
            })
        }, 
        updateDoctor(context, docs) {
            return new Promise((resolve, reject) => {
            docs[0].Id = context.rootState.auth.userId;
              axios.post('/api/Doctor/Update', docs[0])
              .then(response => {
                context.dispatch("getDoctor");
                resolve(response);
              })
              .catch(error => {
                console.log(error);
                reject(error);
              })
            })
        },
        updateLanguage(context, docs) {
            return new Promise((resolve, reject) => {
            docs[0].Id = context.rootState.auth.userId;
              axios.post('/api/Doctor/UpdateLanguage', docs[0])
              .then(response => {
                context.dispatch("getDoctor");
                resolve(response);
              })
              .catch(error => {
                console.log(error);
                reject(error);
              })
            })
        }
    }
}