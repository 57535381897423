<template>
  <div v-if="isConfirmed" class="chart-block">
    <div v-if="selectedIndicator == 1">
      <h5>{{$t('Patients.Indicators.Sugar')}}</h5>
      <div>
        <DxTabs
          :data-source="tabs"
          :selected-index="selectedIndexSugar"
          @selection-changed="onSelectionChangedSugar">
          <template #item="{data}">
            <p>{{$t(data.text)}}</p>
          </template>
        </DxTabs>
      </div>
      <div v-if="selectedIndexSugar == 0" class="content-block">
        <div class="switch">
          <div class="button">
            <DxButton
              id="button-before"
              :width="200"
              :text="$t('Patients.Indicators.OnAnEmptyStomach')"
              type="normal"
              styling-mode="outlined"
              @click="onSugarChart($event)"
            />
          </div>
          <div class="button">
            <DxButton
              id="button-after"
              :width="200"
              :text="$t('Patients.Indicators.AfterEating')"
              type="normal"
              styling-mode="outlined"
              @click="onSugarChart($event)"
            />
          </div>
        </div>
        <div class="date-block">
          <div class="date">
            <p>{{$t('Patients.Indicators.PeriodStart')}}</p>
            <DxDateBox
              width="100%"
              v-model="dateFrom"
              @value-changed="periodChangedSugar"
              displayFormat="monthAndDay"
            />
          </div>
          <div>
            <p>{{$t('Patients.Indicators.PeriodEnd')}}</p>
            <DxDateBox
              width="100%"
              v-model="dateTo"
              @value-changed="periodChangedSugar"
              displayFormat="monthAndDay"
            />
          </div>
        </div>
        <div class="chart">
          <DxChart
            :data-source="sugarData"
            :customize-label="customizePointLabel"
            @argument-axis-click="sugarAxisClicked"
            :resolve-label-overlapping="hide">
            <DxMargin :bottom="20" :top="0" :left="40" :right="40"/>
            <DxCommonSeriesSettings
              type="line"
              argument-field="Date"
            />
            <DxSeries v-for="sugar in sugarIndicators"
              :key="sugar.value"
              :value-field="sugar.value"
              :name="$t(sugar.name)">
              <DxLabel
                :format="{ type: 'fixedPoint', precision: 1 }"
                :font="{ color: 'black', size: '15px', }">
              </DxLabel>
            </DxSeries>
            <DxArgumentAxis
              :value-margins-enabled="true"
              discrete-axis-division-mode="crossLabels">
              <DxLabel
                :customize-text="customizeDayAxis"
                :font="{ color: 'black', size: '15px', weight: 800 }"
              />
              <DxGrid :visible="true"/>
            </DxArgumentAxis>
            <DxValueAxis v-if="mealType === 0"
              :max-value-margin="0.1"
              :min-value-margin="0.1"
              title="ммоль/м">
              <DxStrip :start-value="0" :end-value="3.4" color="#D3E7FF"/>
              <DxStrip :start-value="3.4" :end-value="5.5" color="#D3FFD3"/>
              <DxStrip :start-value="5.5" :end-value="6.1" color="#FFFFD3"/>
              <DxStrip :start-value="6.1" color="#FFD3D3"/>
              <DxLabel
                :font="{ color: 'black', size: '15px' }"
              />
            </DxValueAxis>
            <DxValueAxis v-else
              :max-value-margin="0.1"
              :min-value-margin="0.1">
              <DxStrip :start-value="0" :end-value="3.4" color="#D3E7FF"/>
              <DxStrip :start-value="3.4" :end-value="7.7" color="#D3FFD3"/>
              <DxStrip :start-value="7.7" :end-value="11.1" color="#FFFFD3"/>
              <DxStrip :start-value="11.1" color="#FFD3D3"/>
              <DxLabel
                :font="{ color: 'black', size: '15px' }"
              />
            </DxValueAxis>
            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
              item-text-position="bottom"
            />
            <DxExport :enabled="true"/>
            <DxTooltip :enabled="true"/>
          </DxChart>
        </div>
        <div v-if="mealType === 0" class="cards-block">
          <div v-for="sugar in sugarCardsData" class="card"
            :key="sugar.id"
            :style="sugar.Value <= 3.4 ? { backgroundColor:'#D3E7FF' } :
                    sugar.Value > 3.4 && sugar.Value <= 5.5 ? { backgroundColor:'#D3FFD3' } :
                    sugar.Value > 5.5 && sugar.Value <= 6.1 ? { backgroundColor:'#FFFFD3' } :
                    sugar.Value > 6.1 ? { backgroundColor:'#FFD3D3' } : { }">
            <div class="indicator-controls">
              <h6>{{ sugar.Time }}</h6>
              <p>{{ sugar.Date }}</p>
              <p>{{$t('Patients.Indicators.OnAnEmptyStomach')}}</p>
              <h5>Глюкоза - {{ sugar.Value }}</h5>
            </div>
          </div>
        </div>
        <div v-else-if="mealType === 1" class="cards-block">
          <div v-for="sugar in sugarCardsData"
            :key="sugar.id" class="card"
            :style="sugar.Value <= 3.4 ? { backgroundColor:'#D3E7FF' } :
                    sugar.Value > 3.4 && sugar.Value <= 7.7 ? { backgroundColor:'#D3FFD3' } :
                    sugar.Value > 7.7 && sugar.Value <= 11.1 ? { backgroundColor:'#FFFFD3' } :
                    sugar.Value > 11.1 ? { backgroundColor:'#FFD3D3' } : { }">
            <div class="indicator-controls">
              <h6>{{ sugar.Time }}</h6>
              <p>{{ sugar.Date }}</p>
              <p>{{$t('Patients.Indicators.AfterEating')}}</p>
              <h5>Глюкоза - {{ sugar.Value }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedIndexSugar == 1" class="content-block">
        <div class="switch">
          <div class="button">
            <DxButton
              id="card-button-before"
              :width="200"
              :text="$t('Patients.Indicators.OnAnEmptyStomach')"
              type="normal"
              styling-mode="outlined"
              @click="onSugarCards($event)"
            />
          </div>
          <div class="button">
            <DxButton
              id="card-button-after"
              :width="200"
              :text="$t('Patients.Indicators.AfterEating')"
              type="normal"
              styling-mode="outlined"
              @click="onSugarCards($event)"
            />
          </div>
        </div>
        <div class="calendar-block">
          <DxCalendar
            :value="selectedDate"
            :first-day-of-week="1"
            :zoom-level="zoomLevel"
            @value-changed="sugarDaySelected">
          </DxCalendar>
        </div>
        <div v-if="mealType === 0" class="cards-block">
          <div v-for="sugar in sugarCardsData" class="card"
            :key="sugar.id"
            :style="sugar.Value <= 3.4 ? { backgroundColor:'#D3E7FF' } :
                    sugar.Value > 3.4 && sugar.Value <= 5.5 ? { backgroundColor:'#D3FFD3' } :
                    sugar.Value > 5.5 && sugar.Value <= 6.1 ? { backgroundColor:'#FFFFD3' } :
                    sugar.Value > 6.1 ? { backgroundColor:'#FFD3D3' } : { }">
            <div class="indicator-controls">
              <h6>{{ sugar.Time }}</h6>
              <p>{{ sugar.Date }}</p>
              <p>{{$t('Patients.Indicators.OnAnEmptyStomach')}}</p>
              <h5>Глюкоза - {{ sugar.Value }}</h5>
            </div>
          </div>
        </div>
        <div v-else-if="mealType === 1" class="cards-block">
          <div v-for="sugar in sugarCardsData"
            :key="sugar.id" class="card"
            :style="sugar.Value <= 3.4 ? { backgroundColor:'#D3E7FF' } :
                    sugar.Value > 3.4 && sugar.Value <= 7.7 ? { backgroundColor:'#D3FFD3' } :
                    sugar.Value > 7.7 && sugar.Value <= 11.1 ? { backgroundColor:'#FFFFD3' } :
                    sugar.Value > 11.1 ? { backgroundColor:'#FFD3D3' } : { }">
            <div class="indicator-controls">
              <h6>{{ sugar.Time }}</h6>
              <p>{{ sugar.Date }}</p>
              <p>{{$t('Patients.Indicators.AfterEating')}}</p>
              <h5>Глюкоза - {{ sugar.Value }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedIndicator == 2">
      <h5>{{$t('Patients.Indicators.Pressure')}}</h5>
      <div>
        <DxTabs
          :data-source="tabs"
          :selected-index="selectedIndexPressure"
          @selection-changed="onSelectionChangedPressure">
          <template #item="{data}">
            <p>{{$t(data.text)}}</p>
          </template>
        </DxTabs>
      </div>
      <div v-if="selectedIndexPressure == 0" class="content-block">
        <div class="date-block">
          <div class="date">
            <p>{{$t('Patients.Indicators.PeriodStart')}}</p>
            <DxDateBox
              width="100%"
              v-model="dateFromPressure"
              @value-changed="periodChangedPressure"
              displayFormat="monthAndDay"
            />
          </div>
          <div>
            <p>{{$t('Patients.Indicators.PeriodEnd')}}</p>
            <DxDateBox
              width="100%"
              v-model="dateToPressure"
              @value-changed="periodChangedPressure"
              displayFormat="monthAndDay"
            />
          </div>
        </div>
        <div class="chart">
          <DxChart
            id="chart"
            :data-source="pressureData"
            :customize-label="customizePointLabel"
            @argument-axis-click="pressureAxisClicked"
            :bar-group-padding="0">
            <DxMargin :bottom="20" :top="0" :left="40" :right="40"/>
            <DxSeries v-for="pressure in pressureIndicators"
              :key="pressure.value"
              :value-field="pressure.value"
              :name="$t(pressure.name)"
              :color="pressure.color">
              <DxLabel
                :font="{ color: 'black', size: '14px', }">
                <DxConnector :visible="true"/>
              </DxLabel>
            </DxSeries>
            <DxCommonSeriesSettings
              argument-field="Date"
              type="stackedbar">
              <DxPoint :visible="true"/>
            </DxCommonSeriesSettings>
            <DxValueAxis
              :title="$t('Patients.Indicators.PressureUnit')">
              <DxLabel
                :font="{ color: 'white' }"
              />
            </DxValueAxis>
            <DxArgumentAxis
              :value-margins-enabled="true"
              discrete-axis-division-mode="crossLabels">
              <DxLabel
                :customize-text="customizeDayAxis"
                :font="{ color: 'black', size: '15px', weight: 800 }"
              />
            </DxArgumentAxis>
            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
            />
            <DxExport :enabled="true"/>
            <DxTooltip :enabled="true"/>
          </DxChart>
        </div>
        <div class="cards-block">
          <div v-for="pressure in pressureCardsData"
            :key="pressure.id" class="card pressure">
            <div class="indicator-controls">
              <h6>{{ pressure.Time }}</h6>
              <h6>{{ pressure.Date }}</h6>
              <p
                :style="pressure.LowerPressure < 75 ? { backgroundColor:'#D3FFD3' } :
                        pressure.LowerPressure >= 75 && pressure.LowerPressure < 80 ? { backgroundColor:'#effd02' } :   
                        pressure.LowerPressure >= 80 && pressure.LowerPressure < 90 ? { backgroundColor:'#ffb751' } :   
                        pressure.LowerPressure >= 90 && pressure.LowerPressure < 120 ? { backgroundColor:'#FFD3D3' } :  
                        pressure.LowerPressure >= 120 ? { backgroundColor:'#ee111b', color: 'white' } : { }">
                {{$t('Patients.Indicators.LowerPressure')}}: {{ pressure.LowerPressure }}
              </p>
              <p
                :style="pressure.HigherPressure < 120 ? { backgroundColor:'#D3FFD3' } :                                   
                        pressure.HigherPressure >= 120 && pressure.HigherPressure < 130 ? { backgroundColor:'#effd02' } :   
                        pressure.HigherPressure >= 130 && pressure.HigherPressure < 140 ? { backgroundColor:'#ffb751' } :   
                        pressure.HigherPressure >= 140 && pressure.HigherPressure < 180 ? { backgroundColor:'#FFD3D3' } :  
                        pressure.HigherPressure >= 180 ? { backgroundColor:'#ee111b', color: 'white' } : { }">
                {{$t('Patients.Indicators.HigherPressure')}}: {{ pressure.HigherPressure }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedIndexPressure == 1" class="content-block">
        <div class="calendar-block">
          <DxCalendar
            :readOnly="false"
            :value="selectedDatePressure"
            :first-day-of-week="1"
            :zoom-level="zoomLevel"
            @value-changed="pressureDaySelected">
          </DxCalendar>
        </div>
        <div class="cards-block">
          <div v-for="pressure in pressureCardsData"
            :key="pressure.id" class="card pressure">
            <div class="indicator-controls">
              <h6>{{ pressure.Time }}</h6>
              <h6>{{ pressure.Date }}</h6>
              <p
                :style="pressure.LowerPressure < 75 ? { backgroundColor:'#D3FFD3' } :                                   
                        pressure.LowerPressure >= 75 && pressure.LowerPressure < 80 ? { backgroundColor:'#effd02' } :   
                        pressure.LowerPressure >= 80 && pressure.LowerPressure < 90 ? { backgroundColor:'#ffb751' } :   
                        pressure.LowerPressure >= 90 && pressure.LowerPressure < 120 ? { backgroundColor:'#FFD3D3' } :  
                        pressure.LowerPressure >= 120 ? { backgroundColor:'#ee111b', color: 'white' } : { }">                           
                {{$t('Patients.Indicators.LowerPressure')}}: {{ pressure.LowerPressure }}
              </p>
              <p
                :style="pressure.HigherPressure < 120 ? { backgroundColor:'#D3FFD3' } :                                   
                        pressure.HigherPressure >= 120 && pressure.HigherPressure < 130 ? { backgroundColor:'#effd02' } :   
                        pressure.HigherPressure >= 130 && pressure.HigherPressure < 140 ? { backgroundColor:'#ffb751' } :   
                        pressure.HigherPressure >= 140 && pressure.HigherPressure < 180 ? { backgroundColor:'#FFD3D3' } :  
                        pressure.HigherPressure >= 180 ? { backgroundColor:'#ee111b', color: 'white' } : { }">
                {{$t('Patients.Indicators.HigherPressure')}}: {{ pressure.HigherPressure }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="selectedIndicator == 3">
      <h5>{{$t('Patients.Indicators.HemoglobinLevel')}}</h5>
      <div>
        <DxTabs
          :data-source="tabs"
          :selected-index="selectedIndexHemoglobin"
          @selection-changed="onSelectionChangedHemoglobin">
          <template #item="{data}">
            <p>{{$t(data.text)}}</p>
          </template>
        </DxTabs>
      </div>
      <div v-if="selectedIndexHemoglobin == 0" class="content-block">
        <div class="date-block">
          <div class="date">
            <p>{{$t('Patients.Indicators.PeriodStart')}}</p>
            <DxDateBox
              width="100%"
              v-model="dateFromHemoglobin"
              :calendar-options="{ zoomLevel: 'year', maxZoomLevel: 'year' }"
              displayFormat="monthAndYear"
              @value-changed="periodChangedHemoglobin"
            />
          </div>
          <div>
            <p>{{$t('Patients.Indicators.PeriodEnd')}}</p>
            <DxDateBox
              width="100%"
              v-model="dateToHemoglobin"
              :calendar-options="{ zoomLevel: 'year', maxZoomLevel: 'year' }"
              displayFormat="monthAndYear"
              @value-changed="periodChangedHemoglobin"
            />
          </div>
        </div>
        <div class="chart">
          <DxChart
            :data-source="hemoglobinData"
            @argument-axis-click="hemoglobinAxisClicked"
            :customize-label="customizePointLabel">
            <DxCommonSeriesSettings
              type="line"
              argument-field="Date"
            />
            <DxSeries
              :key="'AvgValue'"
              :value-field="'AvgValue'"
              :name="$t('Patients.Indicators.AvgByMonth')">
              <DxLabel
                :format="{ type: 'fixedPoint', precision: 2 }"
                :font="{ color: 'black', size: '15px', }">
              </DxLabel>
            </DxSeries>
            <DxMargin :bottom="20" :top="0" :left="40" :right="40"/>
            <DxValueAxis
              :max-value-margin="0.1"
              :min-value-margin="0.1"
              title="%">
              <DxLabel
                :format="{ type: 'fixedPoint', precision: 1 }"
                :font="{ color: 'black', size: '15px' }"
              />
            </DxValueAxis>
            <DxArgumentAxis
              :value-margins-enabled="true"
              discrete-axis-division-mode="crossLabels">
              <DxLabel
                :customize-text="customizeMonthAxis"
                :font="{ color: 'black', size: '15px', weight: 800 }"
              />
              <DxGrid :visible="true"/>
            </DxArgumentAxis>
            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
              item-text-position="bottom"
            />
            <DxExport :enabled="true"/>
            <DxTooltip :enabled="true"/>
          </DxChart>
        </div>
        <div class="cards-block">
          <div v-for="hemoglobin in hemoglobinCardsData" class="card"
            :key="hemoglobin.id"
            :style="hemoglobin.Value <= 6 ? { backgroundColor:'#209433' } :
                    hemoglobin.Value > 6 && hemoglobin.Value <= 7 ? { backgroundColor:'#53cc49' } :
                    hemoglobin.Value > 7 && hemoglobin.Value <= 8 ? { backgroundColor:'#9ef01a' } :
                    hemoglobin.Value > 8 && hemoglobin.Value <= 9.4 ? { backgroundColor:'#eafe05' } :
                    hemoglobin.Value > 9.4 && hemoglobin.Value <= 12 ? { backgroundColor:'#f87177' } :
                    hemoglobin.Value > 12 && hemoglobin.Value <= 14 ? { backgroundColor:'#F63B2B' } : { }">
            <div class="indicator-controls">
              <h6>{{ hemoglobin.Date }}</h6>
              <p>{{$t('Patients.Indicators.HemoglobinLevel')}} - {{ hemoglobin.Value }}%</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedIndexHemoglobin == 1" class="content-block">
        <div class="calendar-block">
          <DxCalendar
            :readOnly="false"
            :value="selectedMonthHemoglobin"
            :first-day-of-week="1"
            maxZoomLevel="year"
            :zoom-level="zoomLevel"
            @value-changed="hemoglobinMonthSelected">
          </DxCalendar>
        </div>
        <div class="cards-block">
          <div v-for="hemoglobin in hemoglobinCardsData" class="card"
            :key="hemoglobin.id"
            :style="hemoglobin.Value <= 6 ? { backgroundColor:'#209433' } :
                    hemoglobin.Value > 6 && hemoglobin.Value <= 7 ? { backgroundColor:'#53cc49' } :
                    hemoglobin.Value > 7 && hemoglobin.Value <= 8 ? { backgroundColor:'#9ef01a' } :
                    hemoglobin.Value > 8 && hemoglobin.Value <= 9.4 ? { backgroundColor:'#eafe05' } :
                    hemoglobin.Value > 9.4 && hemoglobin.Value <= 12 ? { backgroundColor:'#f87177' } :
                    hemoglobin.Value > 12 && hemoglobin.Value <= 14 ? { backgroundColor:'#F63B2B' } : { }">
            <div class="indicator-controls">
              <h6>{{ hemoglobin.Date }}</h6>
              <p>{{$t('Patients.Indicators.HemoglobinLevel')}} - {{ hemoglobin.Value }}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxValueAxis ,
  DxCommonSeriesSettings,
  DxExport,
  DxGrid,
  DxMargin,
  DxLegend,
  DxTooltip,
  DxLabel,
  DxStrip,
  DxConnector,
} from 'devextreme-vue/chart';
import DxButton from 'devextreme-vue/button';
import DxDateBox from 'devextreme-vue/date-box';
import DxCalendar from 'devextreme-vue/calendar';
import $ from 'jquery';
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
import notify from 'devextreme/ui/notify';
import DxTabs from 'devextreme-vue/tabs';

export default {
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxValueAxis,
    DxCommonSeriesSettings,
    DxExport,
    DxGrid,
    DxMargin,
    DxLegend,
    DxTooltip,
    DxButton,
    DxDateBox,
    DxLabel,
    DxStrip,
    DxConnector,
    DxCalendar,
    DxTabs
  },
  data() {
    return {
      sugarIndicators: [ {} ],
      pressureIndicators: [ {} ],
      formData: { },
      formDataPressure: { },
      formDataHemoglobin: { },
      dateFrom: new Date(),
      dateFromPressure: new Date(),
      dateFromHemoglobin: new Date(),
      dateTo: new Date(),
      dateToPressure: new Date(),
      dateToHemoglobin: new Date(),
      mealType: 0,
      zoomLevels: ['month', 'year', 'decade', 'century'],
      zoomLevel: 'month',
      selectedIndexSugar: 0,
      selectedIndexPressure: 0,
      selectedIndexHemoglobin: 0,
      tabs: [
        { id: 0, text: 'Patients.Indicators.Chart' },
        { id: 1, text: 'Patients.Indicators.Calendar' },
      ]
    }
  },
  computed: {
    isConfirmed: function() {
      if (this.profile.status == "Прикреплён" || this.profile.status == 2 || this.profile.status == "Тіркелген")
        return true;
      else
        return false;
    },
    sugarData() {
      return this.$store.getters["indicator/getSugar"]//(parseInt(this.$route.params.id));
    },
    sugarCardsData() {
      return this.$store.getters["indicator/getSugarCards"]
    },
    pressureData() {
      return this.$store.getters["indicator/getPressure"]
    },
    pressureCardsData() {
      return this.$store.getters["indicator/getPressureCards"];
    },
    hemoglobinData() {
      return this.$store.getters["indicator/getHemoglobin"]
    },
    hemoglobinCardsData() {
      return this.$store.getters["indicator/getHemoglobinCards"]
    },
  },
  async created() {
    loadMessages(ruMessages);
    locale(navigator.language);

    this.sugarIndicators = [
      { value: 'AvgValue', name: 'Patients.Indicators.AvgValue' },
      { value: 'MaxValue', name: 'Patients.Indicators.MaxValue' },
    ]
    this.pressureIndicators = [
      { value: 'LowerMaxValue', name: 'Patients.Indicators.LowerMaxValue', color: "#1DB2F5" },
      { value: 'HigherMaxValue', name: 'Patients.Indicators.HigherMaxValue', color: "#A8D18D" },
    ]
    if(this.$route.params.id != null) {
      this.profile = this.$store.getters['patient/getPatientById'](this.$route.params.id);
      
      // Сахар:
      this.dateTo = new Date();
      this.dateFrom = new Date(this.dateTo - 1000 * 60 * 60 * 24 * 10);// Сurrent date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)

      this.formData.patientId = parseInt(this.profile.id);
      this.formData.dateTo = new Date();
      this.formData.dateFrom = new Date(new Date() - 1000 * 60 * 60 * 24 * 10);
      
      await this.$store.dispatch("indicator/getMaxAvgSugar", this.formData);
      
      // Давление:
      this.dateToPressure = new Date();
      this.dateFromPressure = new Date(this.dateToPressure - 1000 * 60 * 60 * 24 * 10);
      
      this.formDataPressure.patientId = parseInt(this.profile.id);
      this.formDataPressure.dateTo = new Date();
      this.formDataPressure.dateFrom = new Date(new Date() - 1000 * 60 * 60 * 24 * 10);

      await this.$store.dispatch("indicator/getMaxPressure", this.formDataPressure);
      
      // Гемоглобин:
      this.dateToHemoglobin = new Date();
      this.dateFromHemoglobin = new Date(new Date().setMonth(new Date().getMonth() - 6));

      this.formDataHemoglobin.patientId = parseInt(this.profile.id);
      this.formDataHemoglobin.dateTo = new Date();
      this.formDataHemoglobin.dateFrom = new Date(new Date().setMonth((this.formDataHemoglobin.dateTo.getMonth() - 6)));//new Date(new Date().setMonth(new Date().getMonth() - 6));
      
      await this.$store.dispatch("indicator/getAvgHemoglobin", this.formDataHemoglobin);
    }
  },
  props: {
    profile : {},
    selectedIndicator: {}
  },
  beforeDestroy() {
    this.$store.commit("indicator/setSugar", null);
    this.$store.commit("indicator/setPressure", null);
    this.$store.commit("indicator/setHemoglobin", null);
    this.$store.commit("indicator/setSugarCards", null);
    this.$store.commit("indicator/setPressureCards", null);
    this.$store.commit("indicator/setHemoglobinCards", null);
  },
  methods: {
    async onSugarChart(e) {
      if (e.component.option('text') === this.$i18n.t('Patients.Indicators.OnAnEmptyStomach')) {
        this.mealType = 0;
        $('#button-before')[0].style.background='#4ca3f3';
        $('#button-before')[0].style.color='white';
        $('#button-after')[0].style.background='white';
        $('#button-after')[0].style.color='black';
        
        if (this.dateFrom <= this.dateTo) {
          this.formData = {
            patientId: parseInt(this.profile.id),
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            type: 0
          }
          this.sugarData = await this.$store.dispatch("indicator/getMaxAvgSugar", this.formData);
        } else {
          this.$store.commit("indicator/setSugar", null);
        }
        this.formDataCards = {
          patientId: parseInt(this.profile.id),
          date: this.selectedDate,
          type: 0
        }
      }
      else if (e.component.option('text') === this.$i18n.t('Patients.Indicators.AfterEating')) {
        this.mealType = 1;
        $('#button-after')[0].style.background='#4ca3f3';
        $('#button-after')[0].style.color='white';
        $('#button-before')[0].style.background='white';
        $('#button-before')[0].style.color='black';
        
        if (this.dateFrom <= this.dateTo) {
          this.formData = {
            patientId: parseInt(this.profile.id),
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            type: 1
          }
          this.sugarData = await this.$store.dispatch("indicator/getMaxAvgSugar", this.formData);
          if (this.sugarData === null) {
        this.$store.commit("indicator/setSugarCards", null);
        const message = this.$i18n.t('Patients.Indicators.NoSugarInPeriod');
        notify({
          message
        },
        'error', 1000);
      }
        this.formDataCards = {
          patientId: parseInt(this.profile.id),
          date: this.selectedDate,
          type: 1
        }
      }
        } else {
          this.$store.commit("indicator/setSugar", null);
        }
      this.sugarCardsData = await this.$store.dispatch("indicator/getSugarByDay", this.formDataCards);
    },
    async onSugarCards(e) {
      if (e.component.option('text') === this.$i18n.t('Patients.Indicators.OnAnEmptyStomach')) {
          this.mealType = 0;
          $('#card-button-before')[0].style.background='#4ca3f3';
          $('#card-button-before')[0].style.color='white';
          $('#card-button-after')[0].style.background='white';
          $('#card-button-after')[0].style.color='black';
          
          this.formDataCards = {
            patientId: parseInt(this.profile.id),
            date: this.selectedDate,
            type: 0
          }
        }
        else if (e.component.option('text') === this.$i18n.t('Patients.Indicators.AfterEating')) {
          this.mealType = 1;
          $('#card-button-after')[0].style.background='#4ca3f3';
          $('#card-button-after')[0].style.color='white';
          $('#card-button-before')[0].style.background='white';
          $('#card-button-before')[0].style.color='black';
          
          this.formDataCards = {
            patientId: parseInt(this.profile.id),
            date: this.selectedDate,
            type: 1
          }
        }
      this.sugarCardsData = await this.$store.dispatch("indicator/getSugarByDay", this.formDataCards);
        if (this.sugarCardsData === null) {
          const message = this.$i18n.t('Patients.Indicators.NoSugarOnDay');
          notify({
            message
          },
          'error', 1000);
        }
    },
    async periodChangedSugar() {
      if (this.dateFrom <= this.dateTo) {
        if (this.mealType === 0) {
          this.formData = {
            patientId: parseInt(this.profile.id),
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            type: 0
          }
        }
        else if (this.mealType === 1) {
          this.formData = {
            patientId: parseInt(this.profile.id),
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            type: 1
          }
        }
        this.sugarData = await this.$store.dispatch("indicator/getMaxAvgSugar", this.formData)
        if (this.sugarData === null) {
          this.$store.commit("indicator/setSugarCards", null);
          const message = this.$i18n.t('Patients.Indicators.NoSugarInPeriod');
          notify({
            message
          },
          'error', 1000);
        }
      } else {
        this.$store.commit("indicator/setSugar", null);
      }
    },
    async sugarAxisClicked(e) {
      this.selectedDate = new Date(e.argument);

      if (this.mealType === 0) {
        this.formDataCards = {
          patientId: parseInt(this.profile.id),
          date: new Date(e.argument),
          type: 0
        }
      }
      else {
        this.formDataCards = {
          patientId: parseInt(this.profile.id),
          date: new Date(e.argument),
          type: 1
        }
      }
      this.sugarCardsData = await this.$store.dispatch("indicator/getSugarByDay", this.formDataCards);
    },
    async sugarDaySelected(e) {
      this.selectedDate = e.value;

      if (this.mealType === 0) {
        this.formDataCards = {
          patientId: parseInt(this.profile.id),
          date: e.value,
          type: 0
        }
      }
      else {
        this.formDataCards = {
          patientId: parseInt(this.profile.id),
          date: e.value,
          type: 1
        }
      }
      this.sugarCardsData = await this.$store.dispatch("indicator/getSugarByDay", this.formDataCards)
      if (this.sugarCardsData === null) {
        const message = this.$i18n.t('Patients.Indicators.NoSugarOnDay');
        notify({
          message
        },
        'error', 1000);
      }
    },
    async periodChangedPressure() {
      if (this.dateFromPressure <= this.dateToPressure) {
        console.log("Pressure period changed!")
        this.formDataPressure = {
          patientId: parseInt(this.profile.id),
          dateFrom: this.dateFromPressure,
          dateTo: this.dateToPressure,
        }
        this.pressureData = await this.$store.dispatch("indicator/getMaxPressure", this.formDataPressure)
        this.$store.commit("indicator/setPressureCards", null);
        /*if (this.pressureData === null) {
          const message = this.$i18n.t('Patients.Indicators.NoPressureInPeriod');
          notify({
            message
          },
          'error', 1000);
        }*/
      } else {
        this.$store.commit("indicator/setPressure", null);
      }
    },
    async pressureAxisClicked(e) {
      this.selectedDatePressure = new Date(e.argument);
      this.formDataCards = {
        patientId: parseInt(this.profile.id),
        date: new Date(e.argument),
      }
      this.pressureCardsData = await this.$store.dispatch("indicator/getPressureByDay", this.formDataCards);
    },
    async pressureDaySelected(e) {
      this.selectedDatePressure = e.value;
      this.formDataCards = {
        patientId: parseInt(this.profile.id),
        date: e.value,
      }
      this.pressureCardsData = await this.$store.dispatch("indicator/getPressureByDay", this.formDataCards)
      if (this.pressureCardsData === null) {
        const message = this.$i18n.t('Patients.Indicators.NoPressureOnDay');
        notify({
          message
        },
        'error', 1000);
      }
    },
    async periodChangedHemoglobin() {
      if (this.dateFromHemoglobin <= this.dateToHemoglobin) {
        this.formDataHemoglobin = {
          patientId: parseInt(this.profile.id),
          dateFrom: this.dateFromHemoglobin,
          dateTo: this.dateToHemoglobin,
        }
        this.hemoglobinData = await this.$store.dispatch("indicator/getAvgHemoglobin", this.formDataHemoglobin)
        if (this.hemoglobinData === null) {
          const message = this.$i18n.t('Patients.Indicators.NoHemoglobinInPeriod');
          notify({
            message
          },
          'error', 1000);
        }
      } else {
        this.$store.commit("indicator/setHemoglobin", null);
      }
    },
    async hemoglobinAxisClicked(e) {
      this.selectedMonthHemoglobin = new Date(e.argument);
      this.formDataHemoglobin = {
        patientId: parseInt(this.profile.id),
        date: e.argument,
      }
      this.hemoglobinCardsData = await this.$store.dispatch("indicator/getHemoglobinByMonth", this.formDataHemoglobin);
      if (this.hemoglobinCardsData == null) {
        const message = this.$i18n.t('Patients.Indicators.NoHemoglobinOnMonth');
        notify({
          message
        },
        'error', 1000);
      }
    },
    async hemoglobinMonthSelected(e) {
      this.formDataHemoglobin = {
        patientId: parseInt(this.profile.id),
        date: e.value,
      }
      this.hemoglobinCardsData = await this.$store.dispatch("indicator/getHemoglobinByMonth", this.formDataHemoglobin);
      if (this.hemoglobinCardsData == null) {
        console.log("Нет за месяц")
        const message = this.$i18n.t('Patients.Indicators.NoHemoglobinOnMonth');
        notify({
          message
        },
        'error', 1000);
      }
    },
    customizePointLabel() {
      return {
        visible: true,
        backgroundColor: 'transparent',
      };
    },
    customizeDayAxis(e) {
      return new Date(e.value).getDate() + "." + (new Date(e.value).getMonth() + 1);
    },
    customizeMonthAxis(e) {
      //console.log(e)
      const monthNum = new Date(e.value).getMonth();
      switch (monthNum) {
        case 0:
          return "Январь";
        case 1:
          return "Февраль";
        case 2:
          return "Март";
        case 3:
          return "Апрель";
        case 4:
          return "Май";
        case 5:
          return "Июнь";
        case 6:
          return "Июль";
        case 7:
          return "Август";
        case 8:
          return "Сентябрь";
        case 9:
          return "Октябрь";
        case 10:
          return "Ноябрь";
        case 11:
          return "Декабрь";
        default:
          return "";
      }
    },
    async onSelectionChangedSugar(e) {
      if (this.selectedIndexSugar == 0) {
        this.selectedDate = new Date();
        this.formDataCards = {
          patientId: parseInt(this.profile.id),
          date: new Date(),
          type: this.mealType
        }
        this.sugarCardsData = await this.$store.dispatch("indicator/getSugarByDay", this.formDataCards)
      }
      else if (this.selectedIndexSugar == 1) {
        this.$store.commit("indicator/setSugarCards", null);
        if (this.dateFrom <= this.dateTo) {
          this.formData = {
            patientId: parseInt(this.profile.id),
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            type: this.mealType
          }
          this.sugarData = await this.$store.dispatch("indicator/getMaxAvgSugar", this.formData);
          if (this.sugarData === null) {
            this.$store.commit("indicator/setSugarCards", null);
            const message = this.$i18n.t('Patients.Indicators.NoSugarInPeriod');
            notify({
             message
            },
            'error', 1000);
          }
        } else {
          this.$store.commit("indicator/setSugar", null);
        }
      }
      this.selectedIndexSugar = e.addedItems[0].id
    },
    async onSelectionChangedPressure(e) {
      if (this.selectedIndexPressure == 0) {
        this.selectedDatePressure = new Date();
        this.formDataCards = {
          patientId: parseInt(this.profile.id),
          date: new Date()
        }
        this.pressureCardsData = await this.$store.dispatch("indicator/getPressureByDay", this.formDataCards)
      }
      else if (this.selectedIndexPressure == 1) {
        this.$store.commit("indicator/setPressureCards", null);
        if (this.dateFromPressure <= this.dateToPressure) {
          this.formDataPressure = {
            patientId: parseInt(this.profile.id),
            dateFrom: this.dateFromPressure,
            dateTo: this.dateToPressure
          }
          this.pressureData = await this.$store.dispatch("indicator/getMaxAvgPressure", this.formDataPressure);
          if (this.pressureData === null) {
            this.$store.commit("indicator/setPressureCards", null);
          }
        } else {
          this.$store.commit("indicator/setPressure", null);
        }
      }
      this.selectedIndexPressure = e.addedItems[0].id
    },
    async onSelectionChangedHemoglobin(e) {
      if (this.selectedIndexHemoglobin == 0) {
        this.selectedMonthHemoglobin = new Date();
        this.formDataCards = {
          patientId: parseInt(this.profile.id),
          date: new Date()
        }
        this.hemoglobinCardsData = await this.$store.dispatch("indicator/getHemoglobinByMonth", this.formDataCards)
      }
      else if (this.selectedIndexHemoglobin == 1) {
        this.$store.commit("indicator/setHemoglobinCards", null);
        if (this.dateFromHemoglobin <= this.dateToHemoglobin) {
          this.formDataHemoglobin = {
            patientId: parseInt(this.profile.id),
            dateFrom: this.dateFromHemoglobin,
            dateTo: this.dateToHemoglobin
          }
          this.hemoglobinData = await this.$store.dispatch("indicator/getAvgHemoglobin", this.formDataHemoglobin);
          if (this.hemoglobinData === null) {
            this.$store.commit("indicator/setHemoglobinCards", null);
            const message = this.$i18n.t('Patients.Indicators.NoHemoglobinInPeriod');
            notify({
              message
            },
            'error', 1000);
          }
        } else {
          this.$store.commit("indicator/setHemoglobin", null);
        }
      }
      this.selectedIndexHemoglobin = e.addedItems[0].id

    }
  }
};
</script>
<style scoped lang="scss">
.chart-block {
  margin: 40px auto auto auto;
  width: 100%;
  h5 {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    max-width: 100%;
  }
}
.switch {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
#button-before {
  background-color: #4ca3f3;
  color: white;
}
#card-button-before {
  background-color: #4ca3f3;
  color: white;
}
.button .dx-state-hover {
  background-color: #4ca3f3;
  color: white;
}
.date-block {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px auto auto 50px;
  font-size: 18px;
  p {
    margin: auto;
  }
}
.date {
  margin-right: 250px;
}
.calendar-block {
  display: flex;
  justify-content: center;
  margin: 30px auto;
}
.cards-block {
  margin: auto 50px;
}
.card {
  display: inline-flex;
  height: auto;
  width: 300px;
  margin: 1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  text-align: left;
  max-width: 100%;
  .indicator-controls {
    margin: 1rem;
    max-width: 100%;
  }
  h5, h6 {
    display: inline-block;
    overflow: hidden;
    margin: 0;
    white-space: wrap;
    padding-left: 16px;
    max-width: 100%;
    text-overflow: ellipsis;
  }
  p {
    //color: rgb(78, 78, 78);
    font-size: 16px;
    padding-left: 16px;
  }
}
.pressure {
  display: inline-flex;
  width: 350px;
}
</style>