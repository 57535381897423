import axios from 'axios'

export default {
  namespaced: true,
  state: {
    notifications: [],
    notifNumber: 0
  },
  getters: {
    getNotifications(state) {
      return state.notifications;
    },
    getNotifNumber(state) {
      return state.notifNumber;
    }
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setNotifNumber(state, notifNumber) {
      state.notifNumber = notifNumber
    }
  },
  actions: {
    getNotifications(context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/Notification/GetAllByDoctorId/' + context.rootState.auth.userId)
        .then(response => {
          let tempDoc = [];
          let count = 0;
          response.data.forEach(doc => {
            const data = {
              id: doc.id,
              title: doc.title,
              kztitle: doc.kzTitle,
              kzbody: doc.kzBody,
              body: doc.body,
              patient: doc.patientId,
              createdDate: doc.createdDate,
              type: doc.type,
              read: doc.read
            }
            tempDoc.push(data);
            if (!data.read) count ++;
          });
          context.commit("setNotifications", tempDoc);
          context.commit("setNotifNumber", count);
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
      })
    },
    read(context, id) {
      return new Promise((resolve, reject) => {
        axios.post('/api/Notification/Read' + '?notificationId=' + id)
        .then(response => {
          context.dispatch("getNotifications");
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
      })
    },
    delete(context, id) {
      return new Promise((resolve, reject) => {
        axios.post('/api/Notification/Archive' + '?notificationId=' + id)
        .then(response => {
          context.dispatch("getNotifications");
          resolve(response);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        })
      })
    }
    }
  }
