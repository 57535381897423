<template>
  <dx-scroll-view height="100%" width="100%" class="no-auth-layout">
    <div class="no-auth-layout__content">
      <div class="no-auth-layout__header">
        <div class="no-auth-layout__title">{{ $t(title) }}</div>
        <div class="no-auth-layout__description">{{ description }}</div>
      </div>
      <slot />
    </div>
  </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

export default {
  components: {
    DxScrollView,
  },
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.no-auth-layout {
  
  width: 100%;
  height: 100%;

  &__header {
    margin-bottom: 30px;
    padding: 32px;
  }

  &__title {
    color: $base-text-color;
    line-height: 28px;
    font-weight: 500;
    font-size: 24px;
  }

  &__description {
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    line-height: 18px;
  }
}
</style>
