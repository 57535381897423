import axios from 'axios'
import { v4 as generateGuid } from 'uuid';

export default {
    namespaced: true,
    state: {
        treatmentSchemas: []
    }, 
    getters: {
        getTreatmentSchemas(state){
            return state.treatmentSchemas;
        },
        getTreatmentSchemasById:(state) => id => {
            return state.treatmentSchemas.filter(f => f.id == id)[0];
        }
    },
    mutations: {
        setTreatmentSchemas(state, treatmentSchemas) {
            state.treatmentSchemas = treatmentSchemas;
          }
    },
    actions: {
        getTreatmentSchemas(context, id) {
            return new Promise((resolve, reject) => {
                axios.get('api/TreatmentSchema/GetByPatientId?PatientId=' + id)
                .then(response => {
                    let tempDoc = [];

                    response.data.forEach(doc => {
                        const data = {
                            id: doc.id,
                            dose: doc.dose,
                            medicamentName: doc.medicamentName,
                            medicamentId: doc.medicamentId,
                            timesaday: doc.timesADay
                        }
                        tempDoc.push(data);
                    });
                    context.commit("setTreatmentSchemas", tempDoc);
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                })
            })
        },
        createTreatmentSchema(context, docs) {
            return new Promise((resolve, reject) => {
              axios.post('/api/TreatmentSchema/Create', {"Oid": generateGuid(), "Dose": docs.dose, "timesADay": docs.timesADay, "medicamentId": docs.medicamentId, "patientId": docs.patientId})
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                console.log(error);
                reject(error);
              })
            })
        },
        updateTreatmentSchema(context, docs) {
            return new Promise((resolve, reject) => {
              axios.post('/api/TreatmentSchema/Update', docs)
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                console.log(error);
                reject(error);
              })
            })
        },
        deleteTreatmentSchema(context, data) {
            return new Promise((resolve, reject) => {
                console.log(data);
      
                axios.post('/api/TreatmentSchema/Delete', data)
                .then(response => {
                  resolve(response);
                })
                .catch(error => {
                  console.log(error);
                  reject(error);
                })
              })
        },
    }
}