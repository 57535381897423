import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/home';
import Profile from './views/profile';
import MedicamentsBook from './views/medicament';
import Notification from './views/notification';
import PatientsList from './views/patient/patient-list';
import PatientProfilePage from './views/patient/patient-profile-page';
import defaultLayout from './layouts/side-nav-outer-toolbar';
import simpleLayout from './layouts/single-card';
import noAuthLayout from './layouts/no-auth-layout';
import store from './store/store';
import Users from './views/admin/users';
import AdminNotifications from './views/admin/notifications';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/user-agreement',
      name: 'user-agreement',
      meta: { requiresAuth: false },
      components: {
        layout: noAuthLayout,
        content: () => import('./views/user-agreement'),
      },
    },
    {
      path: '/home',
      name: 'home',
      meta: { requiresAuth: false },
      components: {
        layout: defaultLayout,
        content: Home,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Profile,
      },
    },
    {
      path: '/medicament',
      name: 'medicament',
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: MedicamentsBook,
      },
    },
    {
      path: '/users',
      name: 'users',
      meta: { requiresAuth: true, authorize: 0 },
      components: {
        layout: defaultLayout,
        content: Users,
      },
    },
    {
      path: '/adminnotifications',
      name: 'notifications',
      meta: { requiresAuth: true, authorize: 0 },
      components: {
        layout: defaultLayout,
        content: AdminNotifications,
      },
    },
    {
      path: '/notification',
      name: 'notification',
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Notification,
      },
    },
    {
      path: '/patients-list',
      name: 'patientsList',
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: PatientsList,
      },
    },
    {
      path: '/patientprofile/:id?',
      name: 'patientprofile',
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: PatientProfilePage,
      },
    },
    {
      path: '/login-form',
      name: 'login-form',
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () =>
          import(/* webpackChunkName: "login" */ './views/auth/login-form'),
      },
      props: {
        layout: {
          title: 'Auth.SignIn',
        },
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () =>
          import(
            /* webpackChunkName: "login" */ './views/auth/reset-password-form'
          ),
      },
      props: {
        layout: {
          title: 'Auth.ResetPassword',
        },
      },
    },
    {
      path: '/create-account',
      name: 'create-account',
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () =>
          import(
            /* webpackChunkName: "login" */ './views/auth/create-account-form'
          ),
      },
      props: {
        layout: {
          title: 'Auth.Registration',
        },
      },
    },
    {
      path: '/sms-confirm/:id',
      name: 'sms-confirm',
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () =>
          import(/* webpackChunkName: "login" */ './views/auth/sms-confirm'),
      },
      props: {
        layout: {
          title: 'Auth.ConfirmSms',
        },
      },
    },
    {
      path: '/change-password/:recoveryCode',
      name: 'change-password',
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () =>
          import(
            /* webpackChunkName: "login" */ './views/auth/change-password-form'
          ),
      },
      props: {
        layout: {
          title: 'Auth.ChangePassword',
        },
      },
    },
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/recovery',
      redirect: '/home',
    },
    {
      path: '*',
      redirect: '/home',
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters['auth/loggedIn']) {
      return next({
        name: 'login-form',
        query: { redirect: to.fullPath },
      });
    } else {
      if (to.matched.some((record) => record.meta.authorize == 0))
        if (store.getters['auth/getUserRole'] != 0) {
          //const role = store.getters['auth/getUserRole'];
          return next({ path: '/home' });
        }
      return next();
    }
  } else {
    next();
  }
});

export default router;
