<template>
  <div class="content-block">
    <h2 class="content-block">{{$t('Notifications.MyNotifications')}}</h2>
    <DxPopup :visible="isVisible" 
        @hidden="onHidden"
        :close-on-outside-click="true"
        :title="$t('Notifications.NotificationText')">
        <DxForm :formData="formData" :readOnly="true">
          <DxItem 
            :data-field="selectedTitle">
            <DxLabel 
              :text="$t('Notifications.NotificationTopic')"/>
          </DxItem>
          <DxItem 
            :data-field="selectedBody" editor-type="dxTextArea" :editor-options="{ height: 280, maxLength: 2000 }">
            <DxLabel 
              :text="$t('Notifications.NotificationText')"/>
          </DxItem>
          <DxItem 
            data-field="createdDate" editor-type="dxDateBox">
            <DxLabel 
              :text="$t('Notifications.CreationDate')"/>
          </DxItem>
        </DxForm>
    </DxPopup>
    <DxDataGrid
      class="dx-card wide-card"
      :data-source="notificationsData"
      :allow-column-resizing="true"
      :selection="{ mode: 'single' }"
      :show-borders="false"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      @row-click="onRowClick"
      @row-removing="confirmDelete"
      :ref="dataGrid"
      :no-data-text="this.$i18n.t('Notifications.NotificationsListEmpty')"
      @row-prepared="onRowPrepared"
    >
      <DxPaging :page-size="10" />
      <DxEditing
        :allow-deleting="true"
        :remove-enabled="true"
        texts="{ confirmDeleteMessage: '' }"
      />
      <DxPager :show-page-size-selector="true" :show-info="true" />
      <DxFilterRow :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="400"
        :placeholder="$t('Search')"
      />
      <DxColumn
        data-field="title"
        :caption="$t('Notifications.NotificationTopic')"
        cell-template="notification-title-cell-template"
      />
      <DxColumn
        data-field="body"
        :caption="$t('Notifications.NotificationText')"
        cell-template="notification-text-cell-template"
      />
      <DxColumn
        data-field="createdDate"
        :caption="$t('Notifications.CreationDate')"
        dataType="date"
        format="dd/MM/yyyy HH:mm"
      />
      <DxColumn
        data-field="read"
        :visible="false"
      />
    <template #notification-text-cell-template="{data}">
      {{translateNotification(data)}}
    </template>
    <template #notification-title-cell-template="{data}">
      {{translateNotificationTitle(data)}}
    </template>
    </DxDataGrid>
  </div>
</template>

<script>
import "devextreme/data/odata/store";
import DxDataGrid, {
    DxColumn,
    DxFilterRow,
    DxSearchPanel,
    DxPager,
    DxEditing,
    DxPaging
} from "devextreme-vue/data-grid";
import router from "@/router";
import notify from 'devextreme/ui/notify';
import { custom } from "devextreme/ui/dialog";
import { DxPopup } from 'devextreme-vue/popup';
import { DxForm, DxItem, DxLabel } from "devextreme-vue/form";

export default {
  data() {
    return {
      dataGrid: "gridInstance",
      popupVisible: false,
      formData: {},
      selectedBody: 'body',
      selectedTitle: 'title',
      read: false,
    };
  },
  async created() {
    await this.$store.dispatch("notification/getNotifications");
    this.$root.$emit('sideNavMenu');
  },
  computed: {
    gridInstance: function() {
      return this.$refs["gridInstance"].instance;
    },
    notificationsData() {
      return this.$store.getters["notification/getNotifications"];
    },
    isVisible: function() {
      return this.popupVisible;
    },
    isRead() {
      return this.read;
    }
  },
  methods: {
    onHidden() {
      this.popupVisible = false;
    },
    async onRowClick(e) {
      const data = e.data;
      if (data.type != 3) {
        if(data.patient != "")
        {
          await this.$store.dispatch("patient/getPatients");
          await this.$store.dispatch("notification/read", data.id);
          router.push("/patientprofile/"+ data.patient);
        }
      } else {
        await this.$store.dispatch("notification/read", data.id);
        this.isRead = true;

        this.popupVisible = true;
        this.formData = data;
        if (this.$i18n.locale == 'kz') {
          this.selectedBody = 'kzbody';
          this.selectedTitle = 'kztitle'
        } else {
          this.selectedBody = 'body';
          this.selectedTitle = 'title'
        }
      }
      await this.$store.dispatch("notification/getNotifications");
      
      this.$root.$emit('sideNavMenu');
    },
    confirmDelete(e) {
      e.cancel = true
      if(e.data.Id != "" || e.data != null) {
      let confirmDialog = custom({
          title: this.$i18n.t('Patients.ConfirmAction'),
          messageHtml: this.$i18n.t('Notifications.SureWantToDeleteNotification'),
          buttons: [{
            text: this.$i18n.t('Patients.Yes'),
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            }
          }, {
            text: this.$i18n.t('Patients.No'),
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            },
          }]
        });
        confirmDialog.show().then((dialogResult) => {
          if (dialogResult.buttonText === this.$i18n.t('Patients.No')) {
            e.cancel = true;
          }
          else if (dialogResult.buttonText === this.$i18n.t('Patients.Yes')) {
            this.$store.dispatch("notification/delete", e.data.id);
            this.$store.dispatch("notification/getNotifications");
            this.$root.$emit('sideNavMenu');

            const message = this.$i18n.t('Notifications.NotificationDeleted');
              notify({
                message
              },
              'success', 3000);
          }
        });
      }
    },
    translateNotification(value) {
      if (value.data.type != 3) {
        var splits = value.data.body.replaceAll(/[^a-zA-Zа-яА-Я0-9.,]/g, "").split(",");
        return this.$i18n.t(splits[0], [splits[1], splits[2]]);
      } else {
        if(this.$i18n.locale == 'kz') {
          return value.data.kzbody;
        } else {
          return value.data.body;
        }
      }
    },
    translateNotificationTitle(value) {
      if (value.data.type != 3) {
        return this.$i18n.t(value.data.title);
      } else {
        if (this.$i18n.locale == 'kz') {
        return value.data.kztitle;
        } else {
          return value.data.title;
        }
      }
    },
    onRowPrepared(e, isRead) {
      if (!isRead) {
        if(e.rowType === "data" && !e.data.read) {
          e.rowElement.style.fontWeight = 'bold';
          e.watch(function() {
            return e.data.Amount;
          }, function() {
            e.rowElement.style.fontWeight = 'bold';
          })
        }
      }
    },
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxSearchPanel,
    DxPager,
    DxEditing,
    DxPaging,
    DxPopup,
    DxForm,
    DxItem,
    DxLabel
  }
};
</script>