import axios from 'axios'
import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth/auth';
import patient from './modules/patient/patient';
import medicament from './modules/medicament/medicament';
import notification from './modules/notification/notification';
import treatmentschema from './modules/treatmentschema/treatmentschema';
import doctor from './modules/doctor/doctor';
import indicator from './modules/patient/indicator';
import admin from './modules/admin/admin';

// Load Vuex
Vue.use(Vuex);
axios.defaults.baseURL = 'https://api.diahelp.kz/'
// axios.defaults.baseURL = 'https://localhost:44372/'
// Create store
export default new Vuex.Store({
  modules: {
    auth,
    patient,
    medicament,
    treatmentschema,
    doctor,
    notification,
    indicator,
    admin
  },
  plugins: [createPersistedState()]
});