<template>
  <div id="root">
    <div :class="cssClasses">
      <router-view
        name="layout"
        :title="title"
        :is-x-small="screen.isXSmall"
        :is-large="screen.isLarge"
      >
        <div class="content">
          <router-view name="content" />
        </div>
        <template #footer>
          <app-footer />
        </template>
      </router-view>
    </div>
    <div class="toast-container position-absolute bottom-0 end-0 p-3">
      <div
        v-for="notif in notifications"
        :key="notif.title"
      >
        <div
          class="toast show bg-light"
          role="status"
          aria-live="polite"
          aria-atomic="true"
          data-bs-autohide="true"
          data-bs-delay="10"
        >
          <div class="toast-header">
            <strong class="me-auto">{{notif.title}}</strong>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="toast-body"
            style="max-height: 200px; overflow-y: scroll;"
          >
            {{notif.message}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AppFooter from "./components/app-footer";
  import { sizes, subscribe, unsubscribe } from "./utils/media-query";

  function getScreenSizeInfo() {
    const screenSizes = sizes();

    return {
      isXSmall: screenSizes["screen-x-small"],
      isLarge: screenSizes["screen-large"],
      cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
    };
  }

  export default {
    name: "app",
    data() {
      return {
        title: this.$appInfo.title,
        screen: getScreenSizeInfo(),
        notifMessage: "",
        notifTitle: "",
        notifications: [],
      };
    },
    computed: {
      cssClasses() {
        return ["app"].concat(this.screen.cssClasses);
      },
    },
    methods: {
      screenSizeChanged() {
        this.screen = getScreenSizeInfo();
      },
      async onNewNotification({ title, kztitle, body, kzbody }) {
        this.$root.$emit("sideNavMenu");

        if (this.$i18n.locale == "kz") {
          if (
            title == "Notifications.AttachConfirmationTitle" ||
            title == "Notifications.AcceptConfirmationTitle" ||
            title == "Notifications.DetachConfirmationTitle"
          ) {
            this.notifTitle = "Хабарлама: \n" + this.$i18n.t(kztitle);
            var kzSplits = kzbody
              .replaceAll(/[^a-zA-Zа-яА-Я0-9.,]/g, "")
              .split(",");
            this.notifMessage = this.$i18n.t(kzSplits[0], [
              kzSplits[1],
              kzSplits[2],
            ]);
          } else {
            this.notifTitle = "Хабарлама: \n" + kztitle;
            this.notifMessage = kzbody;
          }
        } else {
          if (
            title == "Notifications.AttachConfirmationTitle" ||
            title == "Notifications.AcceptConfirmationTitle" ||
            title == "Notifications.DetachConfirmationTitle"
          ) {
            this.notifTitle = "Уведомление: \n" + this.$i18n.t(title);
            var ruSplits = body
              .replaceAll(/[^a-zA-Zа-яА-Я0-9.,]/g, "")
              .split(",");
            this.notifMessage = this.$i18n.t(ruSplits[0], [
              ruSplits[1],
              ruSplits[2],
            ]);
          } else {
            this.notifTitle = "Уведомление: \n" + title;
            this.notifMessage = body;
          }
        }
        this.notifications.push({
          title: this.notifTitle,
          message: this.notifMessage,
        });
      },
      updateCounter(){
        this.$root.$emit("sideNavMenu");
      }
    },

    mounted() {
      subscribe(this.screenSizeChanged);
    },

    async created() {
      await this.$store.dispatch("notification/getNotifications");
      this.$notificationHub.$on("new-notification", this.onNewNotification);
      this.$notificationHub.$on("update-counter", this.updateCounter);
    },

    beforeDestroy() {
      this.$notificationHub.$off("new-notification", this.onNewNotification);
      this.$notificationHub.$off("update-counter", this.updateCounter);
      unsubscribe(this.screenSizeChanged);
    },

    components: {
      AppFooter,
    },
  };
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
