import i18n from './i18n';

export default [
  {
    text: i18n.messages.ru.Nav.Home,
    path: '/home',
    icon: 'home',
  },
  {
    text: i18n.messages.ru.Nav.Catalogues,
    icon: 'folder',
    items: [
      {
        text: i18n.messages.ru.Nav.Medicaments,
        path: '/medicament',
      },
    ],
  },
  {
    text: i18n.messages.ru.Nav.Notifications,
    path: '/notification',
    icon: 'message',
  },
  {
    text: i18n.messages.ru.Nav.MyPatients,
    path: '/patients-list',
    icon: 'group',
  },
];
