<template>
  <div>
    <h2 class="content-block">{{$t('Doctor.MyProfile')}}</h2>
    <div class="content-block dx-card responsive-paddings">
      <div class="topContainer">

        <div class="qrCodeContainer">
          <span>{{$t('Doctor.AttachmentCode')}}: {{formData[0].profileId}}</span>
          <VueQRCodeComponent class="qrCode" :text="formData[0].profileId" :size="150" error-level="H"></VueQRCodeComponent>
        </div>

        <div class="buttonsContainer">
          <DxButton class="editButton" v-if="readOnly" 
            @click="editProfile" 
            stylingMode="outlined"
            :text="$t('Doctor.Edit')"
            icon="edit"/>
          <div v-else class="editButtons">
            <DxButton class="editButton"
              @click="editProfile"
              stylingMode="outlined"
              :text="$t('Doctor.Cancel')"
              icon="remove"/>
            <DxButton class="editButton"
              @click="saveProfile"
              stylingMode="outlined"
              :text="$t('Doctor.Save')"
              icon="save"/>
          </div>
        </div>
      </div>

      <DxForm
        id="form"
        :formData="formData[0]"
        :colCountByScreen="colCountByScreen"
        >
        <DxItem data-field="profileId" :editor-options="{readOnly: true}">
          <DxLabel 
            :text="$t('Doctor.Code')"/>
        </DxItem>
        <DxItem data-field="lastName" :editor-options="{readOnly: readOnly}">
          <DxLabel 
            :text="$t('Surname')"/>
        </DxItem>
        <DxItem data-field="firstName" :editor-options="{readOnly: readOnly}">
          <DxLabel 
            :text="$t('Name')"/>
        </DxItem>
        <DxItem data-field="Specialization" :editor-options="{readOnly: readOnly}">
          <DxLabel 
            :text="$t('Doctor.Specialization')"/>
        </DxItem>
        <DxItem data-field="address" editor-type="dxSelectBox" 
        :editor-options="{readOnly: readOnly, searchEnabled:'true', acceptCustomValue:'false', dataSource: cities, grouped:'true', displayExpr:'City', valueExpr:'City'}">
          <DxLabel 
            :text="$t('Doctor.City')"/>
        </DxItem>
        <DxItem data-field="userName" :editor-options="{readOnly: true, mask: '+7 (X00) 000-00-00', maskRules: { 'X': /[01-9]/ }} ">
          <DxLabel 
            :text="$t('Doctor.Phone')"/>
        </DxItem>
      </DxForm>

    </div>
  </div>
</template>
<script>

import { DxForm, DxItem, DxLabel } from "devextreme-vue/form";
import notify from 'devextreme/ui/notify';
import DxButton from 'devextreme-vue/button';
import VueQRCodeComponent from 'vue-qrcode-component';
import Cities from '../cities';
import DataSource from 'devextreme/data/data_source';

export default {
  props: {
    picture: String
  },
  data() {
    const picture = "images/employees/06.png";
    return {
      imageSrc: `https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/${picture}`,
      formData: { },
      colCountByScreen: {
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4
      },
      readOnly: true,
      doctorId: 0,
      cities: new DataSource({
        store: Cities.getCities(),
        key: 'ID',
        group: 'Region'
      })
    };
  },
  created: async function() {
    await this.$store.dispatch('doctor/getDoctor');
    this.formData = this.$store.getters['doctor/getDoctor'];
    this.doctorId = this.$store.getters['doctor/getDoctor'][0].id;
  },
  methods: {
    changePhotoClick() {
      notify('Фото изменено');
    },
    async editProfile() {
      this.readOnly = !this.readOnly;
      await this.$store.dispatch('doctor/getDoctor');
      this.formData = this.$store.getters['doctor/getDoctor'];
    }, 
    async saveProfile() {
      this.readOnly = !this.readOnly;
      await this.$store.dispatch('doctor/updateDoctor', this.formData);
      this.formData = this.$store.getters['doctor/getDoctor'];
    }
  },
  computed:{
    Age() {
      if (this.formData.birthDate.getMonth > new Date().getMonth){
        return (new Date().getFullYear() - this.formData.getFullYear() - 1)
      } else {
        return (new Date().getFullYear() - this.formData.getFullYear())
      }
    }, 
    ProfileId() {
      return this.formData.profileId;
    }
  },
  components: {
    DxForm,
    DxButton,
    DxItem,
    DxLabel, 
    VueQRCodeComponent
  }
};
</script>

<style lang="scss">
.form-avatar {
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;
  min-width: 100px;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}
.qrCodeContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  margin-right: auto;
  width: fit-content;
  padding: 10px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%), 0 1px 2px rgb(0 0 0 / 32%);
  border-radius: 4px;
  span {
    margin-bottom: 5px;
    font-size: 1.6em;
  }
}
.topContainer {
  display: flex;
  flex-wrap: wrap;
}
.editButton {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
.buttonsContainer {
  min-width: 186px;
  .editButtons {
    display: flex;
    flex-direction: column;
  }
}
</style>
