import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import Vue from "vue";

import App from "./App";
import router from "./router";
import appInfo from "./app-info";
import store from "./store/store";
import i18n from './i18n';
import NotificationHub from './notification-hub';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/src/jquery.js';
import 'bootstrap/dist/js/bootstrap.min.js';

Vue.config.productionTip = false;
Vue.prototype.$appInfo = appInfo;
Vue.use(NotificationHub)

new Vue({
  store,
  router,
  i18n,
  NotificationHub,
  render: h => h(App)
}).$mount("#app");
