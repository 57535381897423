import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';

export default {
    install (Vue) {
      const connection = new HubConnectionBuilder()
        .withUrl("https://api.diahelp.kz/notifhub")
        .configureLogging(LogLevel.Information)
        .build()

        const notificationHub = new Vue();
        Vue.prototype.$notificationHub = notificationHub;

        connection.on('NewNotification', (title, kztitle, body, kzbody) => {
            notificationHub.$emit('new-notification', { title, kztitle, body, kzbody })
        });

        connection.on('UpdateNotifCounter', () => {
          notificationHub.$emit('update-counter', { })
        });
   
        let startedPromise = null
        function start () {
          startedPromise = connection.start().catch(err => {
            console.error('Failed to connect with hub', err)
            return new Promise((resolve, reject) => 
              setTimeout(() => start().then(resolve).catch(reject), 5000))
          })
          return startedPromise
        }
        connection.onclose(() => start())
         
        start()
    }
}
