import axios from 'axios'

export default {
    namespaced: true,
    state: {
        medicaments: []
    },
    getters: {
        getMedicaments(state) {
          return state.medicaments;
        }
    },
    mutations: {
        setMedicaments(state, docs) {
          state.medicaments = docs;
        }
    },
    actions: {
      getMedicaments(context) {
        return new Promise((resolve, reject) => {
          axios.get('/api/MedicamentsBook/GetMedicaments/')
          .then(response => {
            let tempDoc = [];

            response.data.forEach(doc => {
              const data = {
                Id: doc.id,
                Name: doc.name,
                Description: doc.description,
                Color: doc.color,
                Role: context.rootState.auth.role
              }
              tempDoc.push(data);
            });
            context.commit("setMedicaments", tempDoc);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          })
        })
      },
      createMedicament(context, docs) {
        return new Promise((resolve, reject) => {
          docs.Id = 0;
          axios.post('/api/MedicamentsBook/Create', docs)
          .then(response => {
            context.dispatch("getMedicaments");
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          })
        })
      },
      updateMedicament(context, docs) {
        return new Promise((resolve, reject) => {
          axios.post('/api/MedicamentsBook/Update', docs)
          .then(response => {
            context.dispatch("getMedicaments");
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          })
        })
      },
      deleteMedicament(context, data) {
        return new Promise((resolve, reject) => {
            axios.post('/api/MedicamentsBook/Delete', data)
            .then(response => {
              context.dispatch("getMedicaments");
              resolve(response);
            })
            .catch(error => {
              console.log(error);
              reject(error);
            })
          })
        }
    }
}