<template>
  <div class="content-block">
    <h2 class="content-block">{{$t('Notifications.MyNotifications')}}</h2>
    <DxDataGrid
      class="dx-card wide-card"
      :data-source="notificationsData"
      :allow-column-resizing="true"
      :selection="{ mode: 'single' }"
      :show-borders="false"
      @saved="onSaved"
      @row-removing="onDeleted"
      :ref="dataGrid"
      no-data-text="Список уведомлений пуст"
    >
      <DxPaging :page-size="10" />
      <DxSearchPanel
        :visible="true"
        :placeholder="$t('Search')"
      />
      <DxPaging :page-size="10" />
      <DxEditing
        v-if="this.$store.getters['auth/getUserRole'] === 0"
        :allow-updating="true"
        :allow-adding="true"
        :allow-deleting="true"
        mode="popup"
      >
        <DxPopup
          :show-title="false"
          :close-on-outside-click="true"
          title="Создать рассылку"
        >
          <DxToolbarItem
            toolbar="bottom"
            location="after"
            widget="dxButton"
            :options="saveButtonOptions"
          />
          <DxToolbarItem
            toolbar="bottom"
            location="after"
            widget="dxButton"
            :options="cancelButtonOptions"
          />
        </DxPopup>
        <DxForm :formData="formData">
          <DxItem
            :col-count="1"
            :col-span="2"
            caption="Создать рассылку"
            item-type="group"
          >
            <DxItem
              data-field="recipients"
              editor-type="dxSelectBox"
              :editor-options="{acceptCustomValue: 'false', dataSource: recipients, displayExpr: 'Recipient', valueExpr: 'ID'}"
            >
              <DxRequiredRule :message="$t('Required')" />
            </DxItem>
            <DxItem
              data-field="title"
              :editor-options="{maxLength: 20}"
            >
              <DxRequiredRule :message="$t('Required')" />
            </DxItem>
            <DxItem
              data-field="kzTitle"
              :editor-options="{maxLength: 20}"
            >
              <DxRequiredRule :message="$t('Required')" />
            </DxItem>
            <DxItem
              data-field="body"
              :editor-options="{ height: 180, maxLength: 2000 }"
              editor-type="dxTextArea"
            >
              <DxRequiredRule :message="$t('Required')" />
            </DxItem>
            <DxItem
              data-field="kzBody"
              :editor-options="{ height: 180, maxLength: 2000 }"
              editor-type="dxTextArea"
            >
              <DxRequiredRule :message="$t('Required')" />
            </DxItem>

          </DxItem>
        </DxForm>
      </DxEditing>
      <DxPager
        :show-page-size-selector="true"
        :show-info="true"
      />
      <DxFilterRow :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="400"
        :placeholder="$t('Search')"
      />
      <DxColumn
        data-field="recipients"
        :caption="$t('Notifications.Recipients')"
        cell-template="recipients-cell-template"
      />
      <DxColumn
        data-field="title"
        :caption="$t('Notifications.NotificationTopic') + ' RU'"
      />
      <DxColumn
        data-field="kzTitle"
        :caption="$t('Notifications.NotificationTopic') + ' KZ'"
      />
      <DxColumn
        data-field="body"
        :caption="$t('Notifications.NotificationText') + ' RU'"
      />
      <DxColumn
        data-field="kzBody"
        :caption="$t('Notifications.NotificationText') + ' KZ'"
      />
      <DxColumn
        data-field="createdDate"
        :caption="$t('Notifications.CreationDate')"
        dataType="date"
        format="dd/MM/yyyy HH:mm"
      />
      <DxColumn type="buttons">
        <DxButton
          :text="$t('Notifications.Send')"
          hint="Отправить уведомление"
          :on-click="sendNotifications"
        />
        <DxButton name="edit" />
        <DxButton name="delete" />
      </DxColumn>
      <template #recipients-cell-template="{data}">
        {{$t(displayRecipients(data))}}
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
  import {
    DxDataGrid,
    DxSearchPanel,
    DxColumn,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxToolbarItem,
    DxButton,
  } from "devextreme-vue/data-grid";
  import { DxItem, DxRequiredRule } from "devextreme-vue/form";
  import notify from "devextreme/ui/notify";
  import { custom } from "devextreme/ui/dialog";
  import { locale, loadMessages } from "devextreme/localization";

  const dataGridRef = "dataGridRef";

  export default {
    data() {
      return {
        dataGrid: "gridInstance",
        dataGridRef,
        formData: {},
        recipientsCaptions: ["Patients.Patients", "Doctor.Doctors", "All"],
        recipients: [
          { ID: 0, Recipient: "Пациенты" },
          { ID: 1, Recipient: "Врачи" },
          { ID: 2, Recipient: "Все" },
        ],
      };
    },
    mounted() {
      console.log("jjhggg");
    },
    created() {
      loadMessages({ ru: { "dxDataGrid-editingConfirmDeleteMessage": "" } });
      locale("ru");

      this.$store.dispatch("admin/getNotifications");
    },
    computed: {
      gridInstance: function () {
        return this.$refs["gridInstance"].instance;
      },
      notificationsData() {
        return this.$store.getters["admin/getNotifications"][0];
      },
      saveButtonOptions: function () {
        return {
          text: "Сохранить",
          type: "success",
          onClick: () => {
            this.gridInstance.saveEditData();
          },
        };
      },
      cancelButtonOptions: function () {
        return {
          text: this.$i18n.t("Patients.Cancel"),
          onClick: () => {
            this.gridInstance.cancelEditData();
          },
        };
      },
    },
    methods: {
      onSaved(e) {
        e.changes[0].data.id = 0;
        this.$store.dispatch("admin/updateNotifications", e.changes[0].data);
      },
      onDeleted(e) {
        let denyDialog = custom({
          title: this.$i18n.t("Patients.ConfirmAction"),
          messageHtml: this.$i18n.t("Notifications.SureWantToDeleteNotification"),
          buttons: [
            {
              text: this.$i18n.t("Patients.Yes"),
              onClick: (e) => {
                return { buttonText: e.component.option("text") };
              },
            },
            {
              text: this.$i18n.t("Patients.No"),
              onClick: (e) => {
                return { buttonText: e.component.option("text") };
              },
            },
          ],
        });
        e.cancel = denyDialog.show().then((dialogResult) => {
          if (dialogResult.buttonText === this.$i18n.t("Patients.Yes")) {
            this.$store.dispatch("admin/deleteNotifications", e.data);
            return false;
          } else {
            return true;
          }
        });
      },
      displayRecipients(data) {
        return this.recipientsCaptions[data.value];
      },
      sendNotifications(e) {
        try {
          this.$store.dispatch("admin/createNotifications", e.row.data);
          notify({ message: "Отправлено", type: "success", displayTime: 2000 });
        } catch {
          notify({ message: "Ошибка", type: "error", displayTime: 2000 });
        }
      },
    },
    components: {
      DxDataGrid,
      DxSearchPanel,
      DxColumn,
      DxPaging,
      DxEditing,
      DxPopup,
      DxForm,
      DxItem,
      DxRequiredRule,
      DxToolbarItem,
      DxButton,
    },
  };
</script>