<template>
  <div>
    <div class="content-block  dx-card">
      <patient-profile-form :profile="profile"/>
    </div>
    <div class="tabsContainer">
      <DxTabs 
        id="tabs" 
        v-if="isConfirmed" 
        :items="tabs" 
        :selected-index="selectedIndex" 
        @selection-changed="onSelectionChanged"
        item-template="item">
        <template #item="{data}" class="item">
          <p>{{$t(data.text)}}</p>
        </template>
      </DxTabs>
    </div>
    <div v-if="isConfirmed && selectedIndex == 0" class="content-block">
      <h2 class="content-block">{{$t('Patients.TreatmentSchemas.Prescriptions')}}</h2>
      <div class="dx-card responsive-paddings-button">
        <!--Adding/Editing treatments schemas popup-->
        <DxPopup 
          :visible="isVisible"
          :show-title="false"
          :close-on-outside-click="true"
          height="400"
          title="Информация о препарате"
          @hidden="onHidden">
          
          <form @submit.prevent="handleSubmit">
            <DxForm 
              :formData="formData">
              <DxItem 
                data-field="MedicamentName" editor-type="dxSelectBox"
                :editor-options="{searchEnabled:'true', acceptCustomValue:'false', items: medicaments}">
                <DxLabel 
                  :text="$t('Patients.TreatmentSchemas.MedicamentName')"/>
                <DxRequiredRule
                  :message="$t('Patients.TreatmentSchemas.MedicamentNameRequired')"/>
              </DxItem>
              <DxItem data-field="Dose">
                <DxLabel 
                  :text="$t('Patients.TreatmentSchemas.Dose')"/>
                <DxRequiredRule
                  :message="$t('Required')"/>
              </DxItem>
              <DxItem data-field="TimesADay">
                <DxLabel 
                  :text="$t('Patients.TreatmentSchemas.HowManyTimesADay')"/>
                <DxPatternRule
                  pattern="^[0-9]*$"
                  :message="$t('Patients.TreatmentSchemas.OnlyDigits')"/>
                <DxRequiredRule
                  :message="$t('Required')"/>
              </DxItem>
              <DxButtonItem v-if="isEditing"
                :button-options="submitButtonOptions"/>
              <DxButtonItem v-else
                :button-options="submitButtonOptions"/>
            </DxForm>
          </form>

        </DxPopup>

        <!--Treatment schemas block-->
        <!--Add treatment schema button-->
        <DxButton 
          :text="$t('Patients.TreatmentSchemas.AddNewPrescription')"
          stylingMode="outlined"
          class="addSchemaButton"
          @click="onVisible(0, false)"/>
        <!--Treatment schema card-->
        <div class="treatmentSchema">
          
          <div v-for="item in TreatmentSchemas" :key="item.id" class="treatmentSchemaItem">
            <div class="colorShape" style="clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);" :style="setColors(item.medicamentName)">&nbsp;</div>
            <div class="colorShape" style="background-color: #fff; opacity: 0.4">&nbsp;</div>
            <div class="colorShape" :style="setColors(item.medicamentName)">&nbsp;</div>
            <div class="colorShape" style="background-color: #fff; opacity: 0.6">&nbsp;</div>
            <div class="colorShape" :style="setColors(item.medicamentName)">&nbsp;</div>
            <div class="colorShape" style="background-color: #fff; opacity: 0.8; ">&nbsp;</div>
            <div class="colorShape" style="margin-right: 0; clip-path: polygon(75% 0%, 25% 50%, 75% 100%, 51% 100%, 0% 50%, 51% 0);" :style="setColors(item.medicamentName)">&nbsp;</div>
            <div class="schemaControls">
              <h4>{{item.medicamentName}}</h4>
              <hr>
              <p>{{$t('Patients.TreatmentSchemas.Dose')}}: {{item.dose}}</p>
              <p>{{$t('Patients.TreatmentSchemas.TimesADay')}}: {{item.timesaday}}</p>
              <DxButton 
                :text="$t('Patients.TreatmentSchemas.Edit')"
                @click="onVisible(item.id, true)"
                stylingMode="text"/>
              <DxButton 
                :text="$t('Patients.TreatmentSchemas.Delete')"
                @click="onDelete(item.id)"
                stylingMode="text"/>
            </div>
            <!--<div class="colorLine" :style="setColors(item.medicamentName)"></div>-->
          </div>
        </div>
      </div>
    </div>
    <!--Patient's indicators block-->
    <div v-if="isConfirmed && selectedIndex >= 1 && selectedIndex <= 3" class="content-block">
      <h2 class="content-block">{{$t('Patients.Indicators.Indicators')}}</h2>
    
      <div class="dx-card">
        <patient-indicators :profile="profile" :selectedIndicator="selectedIndex"/>
      </div>
    </div>

    <div v-if="isConfirmed && selectedIndex == 4" class="content-block">
      <h2 class="content-block">{{$t('Patients.MedHistory.MedTakingHistory')}}</h2>
    
      <div class="dx-card">
        <meds-indicators :profile="profile"/>
      </div>
    </div>
  </div>
</template>

<script>
import PatitentProfile from "./patient-profile-form.vue";
import PatientIndicators from "./indicator.vue";
import MedsIndicators from "./medsindicator.vue";
import { DxForm, DxItem, DxButtonItem, DxLabel, DxRequiredRule, DxPatternRule } from "devextreme-vue/form";
import { DxPopup } from 'devextreme-vue/popup';
import DxButton from 'devextreme-vue/button';
import DxTabs from 'devextreme-vue/tabs';

const submitButtonOptions = {
    text: "Сохранить",
    useSubmitBehavior: true,
    stylingMode:"text"
};

export default ({
  data() {
    return {
        profile : {},
        popupVisible: false,
        submitButtonOptions,
        formData: {},
        activeSchemaId: 0,
        schemas: {},
        medicaments: {},
        editing: false,
        tabs: [
          {id: 0, text: 'Patients.TreatmentSchemas.Prescriptions'}, 
          {id: 1, text: 'Patients.Indicators.Sugar'}, 
          {id: 2, text: 'Patients.Indicators.Pressure'}, 
          {id: 3, text: 'Patients.Indicators.Hemoglobin'}, 
          {id: 4, text: 'Patients.MedHistory.TakingHistory'}
          ],
        selectedIndex: 0
    }
  },
  created: async function() {
    if(this.$route.params.id != null)
    {
        //Fetching patient's data, his treatment schemas and the list of all medicaments
        this.profile = this.$store.getters['patient/getPatientById'](this.$route.params.id);
        await this.$store.dispatch('treatmentschema/getTreatmentSchemas', this.$route.params.id);
        await this.$store.dispatch('medicament/getMedicaments');

        this.medicaments = this.$store.getters["medicament/getMedicaments"].map(m => m.Name);
        this.schemas = this.$store.getters["treatmentschema/getTreatmentSchemas"];
    }
  },
  computed: {
    TreatmentSchemas: function() {
      return this.schemas;
    },
    Medicaments: function() {
      return this.medicaments;
    },
    isEditing: function() {
      return this.editing;
    },
    isVisible: function() {
      return this.popupVisible;
    },
    isConfirmed: function() {
      if (this.profile.status == "Прикреплён" || this.profile.status == 2 || this.profile.status == "Тіркелген")
        return true;
      else
        return false;
    }
  },
  methods: {
    async handleSubmit() {

      let { MedicamentId, Dose, TimesADay } = this.formData;
      //Finding the id of a medicament by it's name
      MedicamentId = this.$store.getters["medicament/getMedicaments"].find(m => m.Name == this.formData.MedicamentName).Id;

      if (this.editing) {
        await this.$store.dispatch('treatmentschema/updateTreatmentSchema', {
          id: this.activeSchemaId,
          dose: Dose,
          timesADay: parseInt(TimesADay),
          medicamentId: MedicamentId,
          patientId: parseInt(this.$route.params.id)
        });
      } 
      else 
      {
        await this.$store.dispatch('treatmentschema/createTreatmentSchema', {
          dose: Dose,
          timesADay: parseInt(TimesADay),
          medicamentId: MedicamentId,
          patientId: parseInt(this.$route.params.id)
        });
      }

      this.popupVisible = false;
      this.formData = {};

      await this.$store.dispatch('treatmentschema/getTreatmentSchemas', this.$route.params.id);
      this.schemas = this.$store.getters["treatmentschema/getTreatmentSchemas"];
    },
    onHidden() {
      this.popupVisible = false;
    },
    onVisible(id, isEditing) {
      this.editing = isEditing;
      //Submit button for treatment schemas will have different text depending on whether the user is adding a new one or editing an existing one
      this.submitButtonOptions = {text: isEditing ? this.$i18n.t('Patients.TreatmentSchemas.Save') : this.$i18n.t('Patients.TreatmentSchemas.Add')};
      this.activeSchemaId = id;
      this.popupVisible = true;
    },
    async onDelete(id) {
      await this.$store.dispatch('treatmentschema/deleteTreatmentSchema', {id});
      await this.$store.dispatch('treatmentschema/getTreatmentSchemas', this.$route.params.id);
      this.schemas = this.$store.getters["treatmentschema/getTreatmentSchemas"];
    },
    setColors(name) {
      return {
        backgroundColor: this.$store.getters["medicament/getMedicaments"].filter(m => m.Name == name).map(c => c.Color)[0]
      }
    },
    onSelectionChanged(e) {
      this.selectedIndex = e.addedItems[0].id;
    }
  },
  components: {
    "patient-profile-form": PatitentProfile,
    "patient-indicators": PatientIndicators,
    "meds-indicators": MedsIndicators,
    DxPopup,
    DxItem,
    DxForm,
    DxButton,
    DxButtonItem,
    DxLabel,
    DxRequiredRule,
    DxPatternRule,
    DxTabs
  }
})
</script>

<style lang="scss">
.treatmentSchema {
  max-width: 1300px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}
.treatmentSchemaItem {
  display: inline-flex;
  height: auto;
  margin: 1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  text-align: left;
  max-width: 100%;
  .schemaControls {
  margin: 1rem;
  margin-left: -10px;
  min-width: 0;
  max-width: 60%;
  }
  h4 {
    display:inline-block;
    overflow: hidden;
    margin: 0;
    white-space: wrap;
    padding-left: 16px;
    max-width: 100%;
    text-overflow: ellipsis;
  }
  p {
    color: rgb(78, 78, 78);
    font-size: 16px;
    padding-left: 16px;
    word-wrap:break-word;
  }
}
@media only screen and (max-width: 400px) {
  .treatmentSchema {
    zoom: 0.85;
    transform: scale(0.85);
    -moz-transform: scale(0.85);
  }
  .addSchemaButton {
    zoom: 0.9;
    transform: scale(0.9);
    -moz-transform: scale(0.9);
  }
}

.addSchemaButton {
  margin: 16px;
}
.tabsContainer {
  margin: 40px auto;
  padding: 0 40px;
  .dx-tab {
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    border-radius: 4px;
    background-color: #fff;
    p {
      color: #000;
    }
  }
  .dx-tab:hover {
    background-color: #ededed;
  }
  .dx-tabs {
    border-spacing: 10px 0px;
  }
}

.colorShape {
  flex-grow: 0;
  height: 100%;
  width: 70px;
  min-width: 70px;
  margin-right: -55px;
  background-color: #000;
  clip-path: polygon(100% 0%, 65% 50%, 100% 100%, 50% 100%, 0% 50%, 50% 0%);
}
</style>

