import axios from 'axios';

export default {
  namespaced: true,
  state: {
    patients: [],
    confirmation: false,
    registerResponce: null,
    attachResponce: null
  },
  getters: {
    getPatients(state) {
      return state.patients;
    },
    getPatientById: state => id => {
      return state.patients.filter(f => f.id == id)[0];
    },
    getConfirmation(state) {
      return state.confirmation;
    },
    getRegisterResponce(state) {
      return state.registerResponce;
    },
    getAttachResponce(state) {
      return state.attachResponce;
    }
  },
  mutations: {
    setPatients(state, patients) {
      state.patients = patients;
    },
    setConfirmation(state, response) {
      state.confirmation = response === '' ? false : true;
    },
    setRegisterResponce(state, responce) {
      state.registerResponce = responce === true ? true : false;
    },
    setAttachResponce(state, responce) {
      state.attachResponce = responce;
    }
  },
  actions: {
    getPatients(context) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/Patient/GetByDoctorId/' + context.rootState.auth.userId)
          .then(response => {
            let tempDoc = [];
            response.data.forEach(doc => {
              const data = {
                id: doc.id.toString(),
                firstName: doc.firstName,
                lastName: doc.lastName,
                userName: doc.userName,
                birthDate: doc.birthDate,
                gender: doc.gender,
                height: doc.height,
                weight: doc.weight,
                diabetType: doc.diabetType,
                status: doc.status,
                emergencyNumber: doc.phoneNumberEmergency
              };

              data.gender =
                data.gender === null
                  ? ''
                  : data.gender === 0
                  ? 'Мужчина'
                  : 'Женщина';
              data.diabetType =
                data.diabetType === null
                  ? ''
                  : data.diabetType === 0
                  ? 'Первый тип'
                  : 'Второй тип';

              if (data.status === 1) {
                data.status = 'Ожидает подтверждения';
              } else if (data.status === 2) {
                data.status = 'Прикреплён';
              }

              if (data.height === 0) data.height = null;
              if (data.weight === 0) data.weight = null;

              tempDoc.push(data);
            });

            context.commit('setPatients', tempDoc);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    registerPatient(context, user) {
      return new Promise((resolve, reject) => {
        user.doctorId = context.rootState.auth.userId;
        axios
          .post('/api/Patient/CreateAndAttach', user)
          .then(response => {
            context.dispatch('getPatients');
            if (response.status === 200) {
              context.commit('setRegisterResponce', true);
            } else if (response.response.status === 400) {
              context.commit('setRegisterResponce', false);
            }
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    attachPatient(context, user) {
      return new Promise((resolve, reject) => {
        user.doctorId = context.rootState.auth.userId;
        axios
          .post('/api/Patient/Attach', user)
          .then(response => {
            context.dispatch('getPatients');
            if (response.status === 200) {
              context.commit('setAttachResponce', 'successAttach');
            } else if (
              response.response.data.message ===
              'Patients.PatientAlreadyAttached'
            ) {
              context.commit('setAttachResponce', 'alreadyAttached');
            } else if (
              response.response.data.message === 'Patients.PatientNotFound'
            ) {
              context.commit('setAttachResponce', 'patientNotFound');
            }
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    acceptRequest(context, patientId) {
      return new Promise((resolve, reject) => {
        patientId = parseInt(patientId);
        axios
          .post('/api/Patient/AcceptRequest', { id: patientId })
          .then(response => {
            context.dispatch('checkConfirmation', patientId);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    detachPatient(context, patientId) {
      return new Promise((resolve, reject) => {
        patientId = parseInt(patientId);
        axios
          .post('/api/Patient/Detach', { id: patientId })
          .then(response => {
            context.dispatch('getPatients');
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    },
    checkConfirmation(context, patientId) {
      return new Promise((resolve, reject) => {
        patientId = parseInt(patientId);
        axios
          .post('api/Patient/CheckConfirmation', { id: patientId })
          .then(response => {
            context.commit('setConfirmation', response.data);
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      });
    }
  }
};
