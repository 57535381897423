<template>
  <div id="attach-popup">
    <DxPopup
      :visible="showAttachPopup"
      :title="$t('Patients.AttachPatient')"
      :drag-enabled="true"
      :close-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      :width="350"
      :height="250"
      @hidden="hidePopup">
      <form @submit.prevent="onSubmit">
        <DxForm
          :ref="formRef"
          :form-data="formData"
          label-location="top">
            <DxSimpleItem
              :label="{ text: $t('Patients.Phone') }"
              data-field="userName"
              editor-type="dxTextBox"
              :editor-options="{ mask: '+7 (000) 000 00 00', maskInvalidMessage: this.$t('Patients.PhoneNumberNotFull') }">
              <DxRequiredRule :message="$t('Auth.PhoneNumberRequired')"/>
            </DxSimpleItem>
            <DxButtonItem horizontal-alignment="center">
              <DxButtonOptions
                :text="this.$i18n.t('Patients.AttachPatient')"
                :use-submit-behavior="true"
                type="default"
                styling-mode="outlined"
              />
            </DxButtonItem>
        </DxForm>
      </form>
    </DxPopup>
  </div>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import {  
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
    DxButtonItem,
    DxButtonOptions
} from 'devextreme-vue/form';
const formRef = "my-popup";

export default {
    components: {
        DxPopup,
        DxForm,
        DxSimpleItem,
        DxRequiredRule,
        DxButtonItem,
        DxButtonOptions,
    },
    props: {
        showAttachPopup: Boolean
    },
    data() {
        return {
            formData: {},
            formRef
        }
    },
    methods: {
        hidePopup() {
            this.$emit("hidePopup");
        },
        onSubmit() {
            console.log("I'was submitted!!")
            this.$emit("attachPatient", this.formData);
            return this.$refs[formRef].instance.resetValues();
        },
    }
}
</script>