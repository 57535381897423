<template>
  <div>
    <h2 class="content-block">{{$t('User.Users')}}</h2>
    <DxDataGrid
      :data-source="usersData"
      :allow-column-resizing="true"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      key-expr="id"
      @row-removing="onDeleted"
      @saved="onSaved"
    >
      <DxSearchPanel :visible="true" :placeholder="$t('Search')"/>
      <DxPaging :page-size="10"/>
      <DxEditing
        v-if="this.$store.getters['auth/getUserRole'] === 0"
        :allow-updating="true"
        :allow-adding="true"
        :allow-deleting="true"
        mode="popup"
        >
        <DxPopup
          :show-title="false"
          :width="750"
          :height="600"
          :close-on-outside-click="true"
          title="Edit user"
          @hidden="onHidden"
        />
        <DxForm>
          <DxItem
            :col-count="2"
            :col-span="2"
            caption="Edit User"
            item-type="group"
          >
            <DxItem data-field="id"/>
            <DxItem data-field="firstName"/>
            <DxItem data-field="lastName"/>
            <DxItem data-field="userName"/>
            <DxItem data-field="gender" editor-type="dxSelectBox"
              :editor-options="{acceptCustomValue:'false', dataSource: genders, displayExpr: 'Gender', valueExpr: 'ID'}"/>
            <DxItem data-field="role" editor-type="dxSelectBox"
              :editor-options="{acceptCustomValue:'false', dataSource: roles, displayExpr: 'Role', valueExpr: 'ID'}"/>
            <DxItem data-field="birthDate"/>
            <DxItem data-field="status" editor-type="dxSelectBox"
              :editor-options="{acceptCustomValue:'false', dataSource: statuses, displayExpr: 'Status', valueExpr: 'ID'}"/>
            <DxItem data-field="doctorId"/>

          </DxItem>
        </DxForm>
      </DxEditing>
      <DxColumn
        :width="107"
        data-field="id"
        caption="Id"
        :allowEditing="false"
      />
      <DxColumn
        width="110"
        data-field="firstName"
        :caption="$t('Name')"
      />
      <DxColumn
        width="110"
        data-field="lastName"
        :caption="$t('Surname')"
      />
      <DxColumn
        width="120"
        data-field="userName"
        :caption="$t('Doctor.Phone')"
      />
      <DxColumn 
        width="95"
        data-field="gender"
        :caption="$t('Patients.Gender')"
        cell-template="gender-cell-template"
      />
      <DxColumn 
        data-field="role"
        :caption="$t('User.Role')"
        cell-template="role-cell-template"
      />
      <DxColumn 
        data-field="birthDate"
        :caption="$t('Patients.BirthDate')"
      />
      <DxColumn 
        width="175"
        data-field="status"
        :caption="$t('Patients.AttachmentStatus')"
        cell-template="status-cell-template"
      />
      <DxColumn 
        width="90"
        data-field="doctorId"
        caption="Doctor Id"
      />
      <DxColumn 
        width="135"
        data-field="doctorFirstName"
        :caption="$t('User.DoctorName')"
      />
      <DxColumn 
        width="135"
        data-field="doctorLastName"
        :caption="$t('User.DoctorSurname')"
      />
      <template #gender-cell-template="{data}">
        {{displayGender(data)}}
      </template>
      <template #role-cell-template="{data}">
        {{$t(displayRole(data))}}
      </template>
      <template #status-cell-template="{data}">
        {{$t(displayStatus(data))}}
      </template>
    </DxDataGrid>
    
    <DxTextArea
        :height="0"
      />
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxSearchPanel,
  DxColumn,
  DxPaging,
  DxEditing,
  DxPopup,
  DxForm
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import DxTextArea from 'devextreme-vue/text-area';
import Roles from '@/roles';
import { custom } from "devextreme/ui/dialog";
import { locale, loadMessages } from "devextreme/localization";


export default {
  data() {
    return {
      roles: Roles.getRoles(),
      genders: [{ID: 0, Gender: 'Мужской'}, {ID: 1, Gender: 'Женский'}],
      statuses: [{ID: 0, Status: 'Нет врача'}, {ID: 1, Status: 'Не подтвержден'}, {ID: 2, Status: 'Подтвержден'}]
    };
  },
  created() {
    loadMessages({"ru": {"dxDataGrid-editingConfirmDeleteMessage": ""}});
    locale("ru");
    this.$store.dispatch("admin/getUsers");
  },
  props:['id'],
  computed: {
      usersData() {
        return this.$store.getters["admin/getUsers"][0].filter(x => x.isActive == true);
      }
  },
  methods: {
    onSaved(e) {
      switch (e.changes[0].type) {
        case "insert":
          e.changes[0].data.id = 0;
          this.$store.dispatch("admin/createUser", e.changes[0].data);
          break;
        case "update":
          this.$store.dispatch("admin/updateUser", e.changes[0].data);
          break;
        default:
          console.log("default");
      }
    },
    onDeleted(e) {
      let denyDialog = custom({
            title: this.$i18n.t('Patients.ConfirmAction'),
            messageHtml: this.$i18n.t('Users.SureWantToDeleteUser'),
            buttons: [{
              text: this.$i18n.t('Patients.Yes'),
              onClick: (e) => {
                return { buttonText: e.component.option("text") }
              }
            }, {
              text: this.$i18n.t('Patients.No'),
              onClick: (e) => {
                return { buttonText: e.component.option("text") }
              },
            }]
        });
      e.cancel = denyDialog.show().then((dialogResult) => {
              if (dialogResult.buttonText === this.$i18n.t('Patients.Yes')) {
                this.$store.dispatch("admin/deleteUser", e.data);
                return false;
              } else {
                return true;
              }
        });
    },
    displayGender(value) {
      if (value.displayValue == 0) {
        return "Мужской";
      } else {
        return "Женский";
      }
    },
    displayRole(value) {
      if (value.displayValue == 0) {
        return 'User.Administrator';
      } else if (value.displayValue == 1) {
        return "Пациент";
      } else {
        return 'Doctor.Doctor';
      }
    },
    displayStatus(value) {
      if (value.displayValue == 0) {
        return 'Patients.NoDoctor';
      } else if (value.displayValue == 1) {
        return 'Patients.AwaitsConfirmation';
      } else {
        return 'Patients.Attached';
      }
    }
  },
  components: {
    DxDataGrid,
    DxSearchPanel,
    DxColumn,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxTextArea
  },
};
</script>
<style>
#gridDeleteSelected .dx-button-text {
    line-height: 0;
}
</style>
