<template>
  <div class="content-block">
    <h2 class="content-block">{{$t('Catalogues.MedicamentsCatalogue')}}</h2>
    <DxDataGrid
      :data-source="medicamentsData"
      :allow-column-resizing="true"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      key-expr="Id"
      @row-removing="onDeleted"
      @saved="onSaved"
      :no-data-text="this.$i18n.t('Catalogues.MedicamentsListEmpty')"
    >
      <DxSearchPanel
        :visible="true"
        :placeholder="$t('Search')"
      />
      <DxPaging :page-size="10" />
      <DxEditing
        v-if="this.$store.getters['auth/getUserRole'] === 0"
        :allow-updating="true"
        :allow-adding="true"
        :allow-deleting="true"
        mode="popup"
      >
        <DxPopup
          :show-title="false"
          :width="550"
          :height="500"
          :close-on-outside-click="true"
          @hidden="onHidden"
        />
        <DxForm>
          <DxItem
            :col-count="2"
            :col-span="2"
            :caption="$t('Catalogues.InfoAboutMedicament')"
            item-type="group"
          >
            <DxItem
              data-field="Id"
              :editor-options="{disabled: true}"
            />
            <DxItem data-field="Name">
              <DxRequiredRule :message="$t('Required')" />
            </DxItem>
            <DxItem
              :col-span="2"
              :editor-options="{ height: 80 }"
              data-field="Description"
              editor-type="dxTextArea"
            />
            <DxItem
              :col-span="2"
              data-field="Color"
              editor-type="dxColorBox"
            >
              <DxRequiredRule :message="$t('Required')" />
            </DxItem>
            <DxColorBox :visible="false"></DxColorBox>

          </DxItem>
        </DxForm>
      </DxEditing>
      <DxColumn
        :width="118"
        data-field="Id"
        :caption="$t('Catalogues.MedicamentId')"
        :allowEditing="false"
      />
      <DxColumn
        width="145"
        data-field="Name"
        :caption="$t('Catalogues.Name')"
      />
      <DxColumn
        data-field="Description"
        :caption="$t('Catalogues.Description')"
      />
      <DxColumn
        data-field="Color"
        :caption="$t('Catalogues.Color')"
        :visible="false"
      />
    </DxDataGrid>

    <DxTextArea :height="0" />
  </div>
</template>
<script>
  import {
    DxDataGrid,
    DxSearchPanel,
    DxColumn,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
  } from "devextreme-vue/data-grid";
  import { DxItem, DxRequiredRule } from "devextreme-vue/form";
  import DxTextArea from "devextreme-vue/text-area";
  import DxColorBox from "devextreme-vue/color-box";
  import { custom } from "devextreme/ui/dialog";
  import { locale, loadMessages } from "devextreme/localization";

  export default {
    data() {
      return {};
    },
    created() {
      loadMessages({ ru: { "dxDataGrid-editingConfirmDeleteMessage": "" } });
      locale("ru");
      this.$store.dispatch("medicament/getMedicaments");
    },
    props: ["id"],
    computed: {
      medicamentsData() {
        return this.$store.getters["medicament/getMedicaments"];
      },
    },
    methods: {
      onSaved(e) {
        if (e.changes.length > 0) {
          switch (e.changes[0].type) {
            case "insert":
              console.log("insert");
              if (e.changes[0]?.data)
                this.$store.dispatch(
                  "medicament/createMedicament",
                  e.changes[0].data
                );
              break;
            case "update":
              console.log(e.changes[0].data);
              if (e.changes[0]?.data)
                this.$store.dispatch(
                  "medicament/updateMedicament",
                  e.changes[0].data
                );
              break;
            default:
              console.log("default");
          }
        }
      },
      onDeleted(e) {
        let denyDialog = custom({
          title: this.$i18n.t("Patients.ConfirmAction"),
          messageHtml: this.$i18n.t("Catalogues.SureWantToDeleteMedicament"),
          buttons: [
            {
              text: this.$i18n.t("Patients.Yes"),
              onClick: (e) => {
                return { buttonText: e.component.option("text") };
              },
            },
            {
              text: this.$i18n.t("Patients.No"),
              onClick: (e) => {
                return { buttonText: e.component.option("text") };
              },
            },
          ],
        });
        e.cancel = denyDialog.show().then((dialogResult) => {
          if (dialogResult.buttonText === this.$i18n.t("Patients.Yes")) {
            this.$store.dispatch("medicament/deleteMedicament", e.data);
            return false;
          } else {
            return true;
          }
        });
      },
    },
    components: {
      DxDataGrid,
      DxSearchPanel,
      DxColumn,
      DxPaging,
      DxEditing,
      DxPopup,
      DxForm,
      DxItem,
      DxTextArea,
      DxColorBox,
      DxRequiredRule,
    },
  };
</script>
<style>
#gridDeleteSelected .dx-button-text {
  line-height: 0;
}
</style>
