import { render, staticRenderFns } from "./medsindicator.vue?vue&type=template&id=39bf50e8&scoped=true&"
import script from "./medsindicator.vue?vue&type=script&lang=js&"
export * from "./medsindicator.vue?vue&type=script&lang=js&"
import style0 from "./medsindicator.vue?vue&type=style&index=0&id=39bf50e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39bf50e8",
  null
  
)

export default component.exports