<template>
  <div class="content-block">
    <div class="button-block">
      <DxButton
        v-if="confirmation"
        :width="250"
        :height="50"
        :text="$t('Patients.Accept')"
        type="success"
        styling-mode="contained"
        @click="onAccept($event)"
      />
      <DxButton
        v-if="this.profile.status === 'Прикреплён' || !confirmation"
        :width="250"
        :height="50"
        :text="$t('Patients.DetachPatient')"
        type="default"
        styling-mode="outlined"
        @click="onDetach($event)"
      />
      <DxButton
        v-if="confirmation"
        :width="250"
        :height="50"
        :text="$t('Patients.Deny')"
        type="danger"
        styling-mode="contained"
        @click="onDeny($event)"
      />
    </div>
    <DxForm :form-data="profile">
      <template #avatar-template="{}">
        <div class="form-avatar"/>
      </template>
      <DxGroupItem
        :col-count="5"
        css-class="first-group">
        <DxGroupItem
          :col-span="5"
          :col-count="2"
          :caption="$t('Patients.PatientPersonalData')">
          <DxGroupItem :col-span="2">
            <DxSimpleItem
              data-field="id"
              :editor-options="{ readOnly: true }"
              :label="{ text: $t('Patients.Code') }"
            />
          </DxGroupItem>
          <DxSimpleItem
            data-field="firstName"
            :editor-options="{ readOnly: true }"
            :label="{ text: $t('Name') }"
          />
          <DxSimpleItem
            data-field="lastName"
            :editor-options="{ readOnly: true}"
            :label="{ text: $t('Surname') }"
          />
          <DxSimpleItem
            data-field="birthDate"
            editor-type="dxDateBox"
            :editor-options="{ birthDateOptions, readOnly: true, displayFormat: 'd/MM/yyyy' }"
            :label="{ text: $t('Patients.BirthDate') }"
          />
          <DxSimpleItem
            :editor-options="{ readOnly: true, mask: '+7 (000) 000 00 00'}"
            data-field="userName"
            :label="{ text: $t('Patients.Phone') }"
          />
          <DxSimpleItem
              :id="statusContainer"
              data-field="status"
              :editor-options="{ readOnly: true, value: translatedStatus}"
              :label="{text: $t('Patients.Status')}"
            />
            <DxSimpleItem
              data-field="diabetType"
              :editor-options="{ readOnly: true}"
              :label="{ text: $t('Patients.DiabetType') }"
            />
            <DxSimpleItem
              data-field="emergencyNumber"
              :editor-options="{ readOnly: true, mask: '+7 (000) 000 00 00' }"
              :label="{ text: $t('Patients.EmergencyNumber') }"
            />
          <DxGroupItem :col-span="2" :col-count="3">
            <DxSimpleItem data-field="gender" :editor-options="{ readOnly: true }" :label="{ text: $t('Patients.Gender') }"/>
            <DxSimpleItem data-field="height" :editor-options="{ readOnly: true }" :label="{ text: $t('Patients.Height') }"/>
            <DxSimpleItem data-field="weight" :editor-options="{ readOnly: true }" :label="{ text: $t('Patients.Weight') }"/>
          </DxGroupItem>
        </DxGroupItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem
} from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';
import notify from 'devextreme/ui/notify';
import { custom } from "devextreme/ui/dialog";

export default {
  components: {
    DxForm,
    DxGroupItem,
    DxSimpleItem,
    DxButton
  },
  methods: {
    onAccept() {
      if(this.$route.params.id != ""){
        let acceptDialog = custom({
          title: this.$i18n.t('Patients.ConfirmAction'),
          messageHtml: this.$i18n.t('Patients.SureWantToAttachPatient'),
          buttons: [{
            text: this.$i18n.t('Patients.Yes'),
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            }
          }, {
            text: this.$i18n.t('Patients.No'),
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            },
          }]
        });
        acceptDialog.show().then((dialogResult) => {
          console.log(dialogResult.buttonText) 
          if (dialogResult.buttonText === this.$i18n.t('Patients.Yes')) {
            this.$store.dispatch("patient/acceptRequest", this.$route.params.id);
            this.profile.status = "Прикреплён";
            const message = this.$i18n.t('Patients.SuccesfullyAttached');
              notify({
                message
              },
              'success', 3000);
          }
        });
      }
    },
    onDetach() {
      let detachDialog = custom({
          title: this.$i18n.t('Patients.ConfirmAction'),
          messageHtml: this.$i18n.t('Patients.SureWantToDetachPatient'),
          buttons: [{
            text: this.$i18n.t('Patients.Yes'),
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            }
          }, {
            text: this.$i18n.t('Patients.No'),
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            },
          }]
        });
        detachDialog.show().then((dialogResult) => {
          console.log(dialogResult.buttonText) 
          if (dialogResult.buttonText === this.$i18n.t('Patients.Yes')) {
            this.$store.dispatch("patient/detachPatient", this.$route.params.id);
            const message = this.$i18n.t('Patients.SuccesfullyDetached');
              notify({
                message
              },
              'error', 3000);
            this.$router.push({
              path: "/patients-list",
              query: { redirect: this.$route.path }
            });
          }
        });
    },
    onDeny() {
      let denyDialog = custom({
          title: this.$i18n.t('Patients.ConfirmAction'),
          messageHtml: this.$i18n.t('Patients.SureWantToDenyAttachmentRequest'),
          buttons: [{
            text: this.$i18n.t('Patients.Yes'),
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            }
          }, {
            text: this.$i18n.t('Patients.No'),
            onClick: (e) => {
              return { buttonText: e.component.option("text") }
            },
          }]
        });
        denyDialog.show().then((dialogResult) => {
          console.log(dialogResult.buttonText) 
          if (dialogResult.buttonText === this.$i18n.t('Patients.Yes')) {
            this.$store.dispatch("patient/detachPatient", this.$route.params.id);
            const message = this.$i18n.t('Patients.DeniedAttachmentRequest');
              notify({
                message
              },
              'error', 3000);
            this.$router.push({
              path: "/patients-list",
              query: { redirect: this.$route.path }
            });
          }
        });
    }
  },
  props: {
    profile: Object
  },
  data: function() {
    return {
      birthDateOptions: { width: '100%' },
      phoneOptions: { mask: '+7 (000) 000-00-00' },
    };
  },
  created() {
    this.$store.dispatch("patient/checkConfirmation", this.$route.params.id);
  },
  computed: {
    confirmation() {
      return this.$store.getters["patient/getConfirmation"];
    },
    translatedStatus() {
      if (this.profile.status == "Прикреплён" || this.profile.status == "Тіркелген"){
        return this.$i18n.t('Patients.Attached')
      } else {
        return this.$i18n.t('Patients.AwaitsConfirmation')
      }
    }
  },
};
</script>
<style>
  .first-group,
  .second-group {
    padding: 20px;
  }
  .second-group {
    background-color: rgba(191, 191, 191, 0.15);
  }
  .form-avatar {
    height: 128px;
    width: 128px;
    margin-right: 10px;
    border: 1px solid #d2d3d5;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .button-block {
    margin: 50px auto 30px auto;
    display: flex;
    justify-content: space-around;
  }
</style>

