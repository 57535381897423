<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button
            icon="menu"
            styling-mode="text"
            @click="toggleMenuFunc"
          />
        </template>
      </dx-item>

      <dx-item
        v-if="title"
        location="before"
        css-class="header-title dx-toolbar-label"
      >
        <template>
          <div>{{ title }}</div>
        </template>
      </dx-item>

      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
        <template #default>
          <div class="tools-right">
            <dx-button
              class="user-button authorization"
              :width="180"
              height="100%"
              styling-mode="text"
            >
              <user-panel
                :user="user"
                :menu-items="userMenuItems"
                menu-mode="context"
              />
            </dx-button>
            <div class="locale-changer">
              <select
                v-model="$i18n.locale"
                @change="selectLanguage($event)"
              >
                <option
                  v-for="(lang, i) in langs"
                  :key="`Lang${i}`"
                  :value="lang"
                >{{ $t(lang) }}</option>
              </select>
            </div>
          </div>
        </template>
      </dx-item>
      <template #menuUserItem>
        <user-panel
          :user="user"
          :menu-items="userMenuItems"
          menu-mode="list"
        />
      </template>
    </dx-toolbar>
  </header>
</template>

<script>
  import DxButton from "devextreme-vue/button";
  import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
  //import auth from "../auth";

  import UserPanel from "./user-panel";

  export default {
    props: {
      menuToggleEnabled: Boolean,
      title: String,
      toggleMenuFunc: Function,
      logOutFunc: Function,
    },
    created() {
      this.$i18n.locale = this.$store.getters["doctor/getLanguage"];
      //auth.getUser().then((e) => this.user = e.data);
    },
    data() {
      return {
        user: {},
        langs: ["ru", "kz"],
      };
    },
    computed: {
      userMenuItems: function () {
        return [
          {
            text: this.$i18n.t("Doctor.MyProfile"),
            icon: "user",
            onClick: this.onProfileClick,
          },
          {
            text: this.$i18n.t("Doctor.Logout"),
            icon: "runner",
            onClick: this.onLogoutClick,
          },
        ];
      },
    },
    methods: {
      onLogoutClick() {
        //auth.logOut();
        this.$router.push({
          path: "/login-form",
          query: { redirect: this.$route.path },
        });
      },
      onProfileClick() {
        this.$router.push({
          path: "/profile",
          query: { redirect: this.$route.path },
        });
      },
      selectLanguage(e) {
        var doc = [{ Id: 0, Language: e.target.value }];
        this.$store.dispatch("doctor/updateLanguage", doc);
      },
    },
    components: {
      DxButton,
      DxToolbar,
      DxItem,
      UserPanel,
    },
  };
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}
.tools-right {
  display: flex;
  align-items: center;
}
:focus-visible {
  outline: none;
}
.locale-changer {
  // float: right;

  select {
    height: 30px;
    text-align: center;
    font-size: 1.2em;
    border: 1px solid lightgray;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 16px;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    // text-indent: 1px;
    // text-overflow: "";
  }
}
</style>
