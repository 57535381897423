<template>
  <div
    class="dx-swatch-additional side-navigation-menu"
    @click="forwardClick"
  >
    <slot />
    <div class="menu-container">
      <dx-tree-view
        :ref="treeViewRef"
        :items="Items"
        key-expr="path"
        selection-mode="single"
        :focus-state-enabled="false"
        expand-event="click"
        @item-click="handleItemClick"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
  import DxTreeView from "devextreme-vue/ui/tree-view";
  import { sizes } from "../utils/media-query";
  // import navigation from '../app-navigation';

  const treeViewRef = "treeViewRef";
  const isLargeScreen = sizes()["screen-large"];

  export default {
    props: {
      compactMode: Boolean,
    },
    data() {
      return {
        treeViewRef,
        items: [],
      };
    },
    computed: {
      Items: function () {
        return this.items;
      },
    },
    methods: {
      forwardClick(...args) {
        this.$emit("click", args);
      },

      handleItemClick(e) {
        if (!e.itemData.path || this.compactMode) {
          return;
        }

        this.$router.push(e.itemData.path);

        const pointerEvent = e.event;
        pointerEvent.stopPropagation();
      },

      updateSelection() {
        if (!this.treeView) {
          return;
        }

        this.treeView.selectItem(this.$route.path);
        this.treeView.expandItem(this.$route.path);
      },
      async setItems(isAdmin) {
        await this.$store.dispatch("notification/getNotifications");
        this.notifNum = this.$store.getters["notification/getNotifNumber"];

        this.items[0].text = this.$i18n.t("Nav.Home");
        this.items[1].text = this.$i18n.t("Nav.Catalogues");
        this.items[1].items[0].text = this.$i18n.t("Nav.Medicaments");
        if (isAdmin) {
          this.items[1].items[1] = {
            text: this.$i18n.t("User.Users"),
            path: "/users",
          };
          this.items.pop();
          this.items.pop();
          this.items.push({
            text: this.$i18n.t("Nav.Notifications"),
            path: "/adminnotifications",
            icon: "message",
          });
        } else {
          if (this.items[1].items[1] != null) this.items[1].items.pop();

          if (this.notifNum != 0)
            this.items[2].text =
              this.$i18n.t("Nav.Notifications") + " + " + this.notifNum;
          else this.items[2].text = this.$i18n.t("Nav.Notifications");

          this.items[3].text = this.$i18n.t("Nav.MyPatients");
        }
      },
      prepareNav() {
        import("../app-navigation").then((navigation) => {
          this.items = navigation.default.map((item) => {
            if (item.path && !/^\//.test(item.path)) {
              item.path = `/${item.path}`;
            }
            return { ...item, expanded: isLargeScreen };
          });
          this.treeView =
            this.$refs[treeViewRef] && this.$refs[treeViewRef].instance;
          this.updateSelection();
          this.treeView.collapseAll();
          if (this.compactMode) {
            this.treeView.collapseAll();
          }
          if (this.$store.getters["auth/getUserRole"] == 0) {
            this.setItems(true);
          } else {
            this.setItems(false);
          }
        });
      },
    },
    created() {
      this.notifNum = this.$store.getters["notification/getNotifNumber"];
    },
    mounted() {
      this.prepareNav();
      const thisInstanse = this;
      this.$root.$on("sideNavMenu", function () {
        thisInstanse.setItems(this.isAdmin);
      });
    },
    watch: {
      $route() {
        this.updateSelection();
        this.setItems(this.isAdmin);
      },
      compactMode() {
        if (this.compactMode) {
          this.treeView.collapseAll();
        } else {
          this.updateSelection();
        }
      },
      "$i18n.locale": {
        handler: function () {
          this.prepareNav();
        },
        deep: true,
      },
    },
    components: {
      DxTreeView,
    },
  };
</script>

<style lang="scss">
@import "../dx-styles.scss";
@import "../themes/generated/variables.additional.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;

  .menu-container {
    min-height: 100%;
    display: flex;
    flex: 1;

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        padding-right: 0;

        .dx-icon {
          width: $side-panel-min-width !important;
          margin: 0 !important;
        }
      }
      // ##

      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }
      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level="1"] {
          font-weight: bold;
          border-bottom: 1px solid $base-border-color;
        }

        &[aria-level="2"] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width;
        }
      }
      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
            background: transparent;
          }

          &.dx-state-selected > .dx-treeview-item * {
            color: $base-accent;
          }

          &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
            background-color: lighten($base-bg, 4);
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container
        .dx-treeview-node.dx-state-selected.dx-state-focused
        > .dx-treeview-item
        * {
        color: inherit;
      }
    }
    // ##
  }
}
</style>
