<template>
  <div class="content-block">
    <h2 class="content-block">{{$t('Patients.MyPatients')}}</h2>
    <attach-popup
      :showAttachPopup="showAttachPopup"
      @attachPatient="attachPatient"
      @hidePopup="hidePopup"
    />
    <DxDataGrid
      class="dx-card wide-card"
      :data-source="patientsData"
      :selection="{ mode: 'single' }"
      :show-borders="true"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      @selection-changed="onSelectionChanged"
      @saving="onSaving"
      @cell-prepared="onCellPrepared"
      :ref="dataGrid"
      :no-data-text="this.$i18n.t('Patients.PatientsListEmpty')"
      @toolbar-preparing="onToolbarPreparing">
      <DxEditing
        :allow-adding="true"
        mode="popup">
        <DxPopup
          :show-title="true"
          :drag-enabled="false"
          :title="$t('Patients.RegisterPatient')"
          :width="400"
          :height="425"
          :close-on-outside-click="true">
          <DxToolbarItem
            toolbar="bottom"
            location="center"
            widget="dxButton"
            :options="saveButtonOptions"
          />
        </DxPopup>
        <DxForm>
          <DxItem :col-count="1" item-type="group">
            <DxItem
              data-field="userName"
              editor-type="dxTextBox"
              :editor-options="{ mask: '+7 (000) 000 00 00', maskInvalidMessage: this.$t('Patients.PhoneNumberNotFull'), width: 200 }">
              <DxRequiredRule :message="$t('Auth.PhoneNumberRequired')"/>
            </DxItem>
            <DxItem
              data-field="firstName"
              editor-type="dxTextBox"
              :editor-options="{ width: 200 }">
              <DxRequiredRule :message="$t('Patients.EnterFirstName')"/>
            </DxItem>
            <DxItem
              data-field="lastName"
              editor-type="dxTextBox"
              :editor-options="{ width: 200 }">
              <DxRequiredRule :message="$t('Patients.EnterSecondName')"/>
            </DxItem>
          </DxItem>
        </DxForm>
        </DxEditing>
      <DxPaging :page-size="10" />
      <DxPager :show-page-size-selector="true" :show-info="true" />
      <DxFilterRow :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search')"
      />
      <DxColumn data-field="id" :width="90" />
      <DxColumn
        data-field="firstName"
        :caption="$t('Name')"
        :width="190"
      />
      <DxColumn
        data-field="lastName"
        :caption="$t('Surname')"
        :width="190"
      />
      <DxColumn
        data-field="birthDate"
        :caption="$t('Patients.BirthDate')"
        dataType="date"
        format="dd/MM/yyyy"
      />
      <DxColumn
      data-field="gender"
      :caption="$t('Patients.Gender')"
      />
      <DxColumn
        data-field="userName"
        :caption="$t('Patients.Phone')"
      />
      <DxColumn
        data-field="height"
        :caption="$t('Patients.Height')"
      />
      <DxColumn
        data-field="weight"
        :caption="$t('Patients.Weight')"
      />
      <DxColumn
        data-field="diabetType"
        :caption="$t('Patients.DiabetType')"
      />
      <DxColumn
        data-field="status"
        :caption="$t('Patients.AttachmentStatus')"
        cell-template="status-cell-template"
      />
      <DxColumn
        :group-index="0"
        data-field="status"
        :caption="$t('Patients.Status')"
        group-cell-template="statusGroup-cell-template"
      />
    <template #status-cell-template="{data}">
      {{translateStatus(data)}}
    </template>
    <template #statusGroup-cell-template="{data}">
      {{translateGroupStatus(data)}}
    </template>
    </DxDataGrid>
  </div>
</template>
<script>
import AttachPopup from "../patient/attach-popup";
import "devextreme/data/odata/store";
import DxDataGrid, {
    DxForm,
    DxEditing, 
    DxColumn,
    DxFilterRow,
    DxSearchPanel,
    DxPager,
    DxPaging,
    DxPopup,
    DxToolbarItem,
} from "devextreme-vue/data-grid";
import { DxItem, DxRequiredRule,  } from 'devextreme-vue/form';

import router from "@/router";
import notify from 'devextreme/ui/notify';

export default {
  components: {
    AttachPopup,
    DxForm,
    DxEditing, 
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxSearchPanel,
    DxPager,
    DxPaging,
    DxPopup,
    DxItem,
    DxToolbarItem,
    DxRequiredRule,
  },
  props: {
    id: Object
  },
  data() {
    return {
      showAttachPopup: false,
      dataGrid: "gridInstance",
    };
  },
  created() {
    this.$store.dispatch("patient/getPatients");
    this.$notificationHub.$on('new-notification', this.onNewNotification);
  },
  computed: {
    gridInstance: function() {
      return this.$refs["gridInstance"].instance;
    },
    patientsData() {
      return this.$store.getters["patient/getPatients"];
    },
    saveButtonOptions: function() {
      return {
        text: this.$i18n.t('Patients.Add'),
        type:"default",
        stylingMode:"outlined",
        onClick: () => {
          this.gridInstance.saveEditData();
        }
      }
    },
  },
  methods: {
    async onNewNotification() {
      await this.$store.dispatch("patient/getPatients");
    },
    onToolbarPreparing(e) {
      var vueObjects = this;
      let toolbarItems = e.toolbarOptions.items;
      toolbarItems.forEach(item => {
        if (item.name === "addRowButton") {
          item.showText = 'always';
          item.options.text = this.$i18n.t('Patients.RegisterPatient');
        }
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: this.$i18n.t('Patients.AttachPatient'),
          icon: 'newfolder',
          onClick: function() {
            vueObjects.showAttachPopup = true;
          }
        },
        location: 'before'
      });
    },
    hidePopup() {
      var vueObjects = this
      vueObjects.showAttachPopup = false;
    },
    async attachPatient(e) {
      var vueObjects = this;
      vueObjects.showAttachPopup = false;
      await this.$store.dispatch("patient/attachPatient", e)
      if (this.$store.getters["patient/getAttachResponce"] === "successAttach") {
        const message = this.$i18n.t('Patients.RequestSentToPatient');
        notify({
          message
        },
        'success', 5000);
      }
      else if (this.$store.getters["patient/getAttachResponce"] == "alreadyAttached") {
        const message = this.$i18n.t('Patients.AlreadyAttached');
        notify({
          message
        },
        'error', 5000);
      }
      else if (this.$store.getters["patient/getAttachResponce"] == "patientNotFound") {
        const message = this.$i18n.t('Patients.PatientNotFound');
        notify({
          message
        },
        'error', 5000);
      }
    },
    onSelectionChanged({ selectedRowsData }) {
      const data = selectedRowsData[0];
      if(data.id != "")
      {
        router.push("/patientprofile/"+ data.id);
      }
    },
    translateStatus(value){ 
      if (value.data.status == "Прикреплён"){
        return this.$i18n.t('Patients.Attached')
      } else {
        return this.$i18n.t('Patients.AwaitsConfirmation')
      }
    },
    translateGroupStatus(value){
      if (value.displayValue == "Прикреплён"){
        return this.$i18n.t('Patients.Status') + ": " + this.$i18n.t('Patients.Attached');
      } else {
        return this.$i18n.t('Patients.Status') + ": " + this.$i18n.t('Patients.AwaitsConfirmation');
      }
    },
    async onSaving(e) {
      if(e.changes[0]?.data)
        await this.$store.dispatch("patient/registerPatient", e.changes[0].data)
      if (this.$store.getters["patient/getRegisterResponce"]) {
        const message = this.$i18n.t('Patients.RequestSentToPatient');
        notify({
          message
        },
        'success', 5000);
      }
      else {
        const message = this.$i18n.t('Patients.AlreadyAttached');
        notify({
          message
        },
        'error', 5000);
      }
    },
    onCellPrepared(e) {
      if(e.rowType === "data" && e.column.dataField === "status") {
        e.cellElement.style.backgroundColor = e.data.status === "Прикреплён" ? "#8bc34a" : "#ffc107";
        e.watch(function() {
          return e.data.Amount;
        }, function() {
          e.cellElement.style.backgroundColor = e.data.status === "Прикреплён" ? "#8bc34a" : "#ffc107";
        })
      }
    },
    phoneMask(cellInfo) {
      return "+7 (" + cellInfo.value.substr(0,3) + ") " + cellInfo.value.substr(3,3) + " " + cellInfo.value.substr(6,2) + " " + cellInfo.value.substr(8,2) + " "
    }
  },
};
</script>